export const toUTCDateString = (date: Date) => {
	const day = date.getUTCDate();
	const month = date.getUTCMonth() + 1;

	const UTCDateString = `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}`;

	return UTCDateString;
};

export const getFormattedHour = (hour: number) => {
	const formattedHour = `${hour < 10 ? `0${hour}` : hour}h`;

	return formattedHour;
};

export const dateWithIncrementedHours = (date: Date, hours: number): Date => {
	const updatedDate = new Date(date);

	updatedDate.setHours(updatedDate.getHours() + hours);

	return updatedDate;
};

export const dateWithIncrementedDays = (date: Date, days: number): Date => {
	const updatedDate = new Date(date);

	updatedDate.setDate(updatedDate.getDate() + days);
	updatedDate.setUTCHours(0, 0, 0, 0);

	return updatedDate;
};

export const todayMinusSomeDays = (minusDays: number): Date => {
	const today = new Date();

	const todayMinusSomeDays = dateWithIncrementedDays(today, -minusDays);

	return todayMinusSomeDays;
};

export const getDatesInBetween = (fromDate: Date, toDate: Date, partBy: number): Date[] => {
	const dateArray: Date[] = [];

	let currentDate = fromDate;

	while (currentDate <= toDate) {
		dateArray.push(currentDate);
		currentDate = dateWithIncrementedDays(currentDate, Math.abs(partBy));
	}

	return dateArray;
};

export const getDayPartedHours = (partBy: number): number[] => {
	const partedHours: number[] = [0];

	let currentHour = 0;

	while (currentHour <= 23) {
		if (currentHour % partBy === 0) {
			partedHours.push(currentHour);
		}

		currentHour++;
	}

	return partedHours;
};
