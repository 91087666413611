import { makeStyles } from "@material-ui/core";

import { isMobile } from "@/utils/checkDevice";

const useStyles = makeStyles(theme => ({
	animation: {
		maxWidth: 400,
		width: "100%",
		height: "auto"
	},
	paper: {
		borderRadius: isMobile ? 0 : theme.spacing(1),
		...(isMobile ? {
			maxWidth: "none",
			maxHeight: "none",
			width: "100%",
			height: "100%",
			margin: 0
		} : {})
	},
	dialogContainer: {
		position: "relative",
		height: "100%",
		padding: theme.spacing(3)
	}
}));

export default useStyles;
