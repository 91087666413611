import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import GoogleTagManagerService from "@/services/GoogleTagManager";

import { PageType } from "@/@fp/protocols/Page";

import {
	isLastItem,
	isFirstItem,
	getMinMaxFromArray
} from "@/utils/array";

import {
	toUTCDateString,
	getDatesInBetween,
	getFormattedHour,
	getDayPartedHours
} from "@/utils/date";

export type Metrics = {
	site: {
		access: {
			all: number
			daily: Array<{
				count: number
				date: Date
			}>
			byOrigin: Array<{
				name: string
				count: number
			}>
		}
	}
	pages: Array<{
		type: PageType
		id: number
		name: string
		clicks: Array<{
			name: string
			type: "link"
			id: number
			count: number
		}>
	}>
}

export type MetricFilters = {
	fromDate: Date
	interval: "1d" | "1h"
	device?: "Mobile" | "Desktop"
}

type MetricType = "site-fp-access" | "page-link-click"

type MetricData = {
	customerId?: number
	siteId?: number
	pageId?: number
	sectionId?: number
	pageType?: string
	linkIndex?: number
	linkUrl?: string
	linkName?: string
	linkId?: string
}

type MetricVariables = {
	"fp-site-id"?: number
	"fp-page-id"?: number
	"fp-customer-id"?: number
	"fp-page-type"?: string
}

type ElementMetrics = {
	"fp-link-index"?: string
	"fp-link-url"?: string
	"fp-link-name"?: string
	"fp-link-id"?: string
	"fp-section-id"?: number
}

type RawPlotData = Array<{
	x: Date,
	y: number
}>

type FormattedPlotData = {
	plotData: Array<{
		x: string
		y: number
	}>
	tickValues: string[]
	yDomain: {
		min: number
		max: number
	}
}

type GetFormattedPlotDataProps = {
	rawPlotData: RawPlotData
	interval: MetricFilters["interval"]
	fromDate: Date
	/**
	 * Maximum amount of tick values between the first and the last tick values.
	 */
	maxInnerTickValues: number
}

class MetricService {
	private readonly metricPrefix = "metric-"

	private readonly metricMainProperty = `${this.metricPrefix}element`

	async getSiteMetrics (siteId: number, metricFilters: MetricFilters): Promise<Metrics | null> {
		try {
			const response = await ApiService.get("/site/metrics", {
				params: {
					...metricFilters,
					siteId
				}
			});

			const metrics = response?.data?.metrics as Metrics;

			return metrics;
		} catch (error) {
			ErrorHandlerService.handle(error);
			return null;
		}
	}

	async createMetric (type: MetricType, data: MetricData) {
		try {
			await ApiService.post("/metrics", {
				...data,
				type,
				referrer: document.referrer
			});
		} catch (error) {
			ErrorHandlerService.handle(error);
		}
	}

	/**
	 * Gets formatted prop values in order to avoid prop collision
	 * on HTML elements.
	 */
	getElementMetricProps (props: ElementMetrics): object {
		const metricPropsWithPrefix = this.addMetricPrefixToProps(props);

		/**
		 * It is a way to recognize later a metric element.
		 */
		const elementMetricMainProp = {
			[this.metricMainProperty]: Date.now()
		};

		return {
			...metricPropsWithPrefix,
			...elementMetricMainProp
		};
	}

	/**
	 * Store some metric variables inside Google Tag Manager to consume it later.
	 * Besides, it adds a prefix on given variables in order to avoid variable name
	 * collision inside Google Tag Manager.
	 */
	addMetricVariables (variables: MetricVariables) {
		const requestedVariables = this.addMetricPrefixToProps(variables);
		// eslint-disable-next-line
		const oldVariables = GoogleTagManagerService.dataLayerVariables as any
		// eslint-disable-next-line
		const newVariables = {} as any

		Object.entries(requestedVariables || {}).forEach(([key, value]) => {
			const oldVariableValue = oldVariables?.[key];

			if (oldVariableValue !== value) {
				newVariables[key] = value;
			}
		});

		const isThereAnyNewVariable = Object.keys(newVariables).length > 0;

		if (isThereAnyNewVariable) {
			GoogleTagManagerService.addDataLayerVariables(newVariables);
		}
	}

	/**
	 * Setup all FP metrics, it is a function usually triggered by the 'onload' event.
	 */
	setupFPMetrics () {
		/**
		 * Site access tracking
		 */
		const siteFPAccessMetricVariables = this.getMetricVariables();

		this.createMetric("site-fp-access", {
			customerId: siteFPAccessMetricVariables["fp-customer-id"],
			siteId: siteFPAccessMetricVariables["fp-site-id"]
		});

		/**
		 * Link click tracking
		 */
		window.addEventListener("click", event => {
			const metrics = this.getEventMetrics(event);

			const isThereAnyLinkMetrics = Object.keys(metrics).some(key => key.includes("link"));

			if (isThereAnyLinkMetrics) {
				const metricVariables = this.getMetricVariables();

				this.createMetric("page-link-click", {
					customerId: metricVariables["fp-customer-id"],
					pageId: metricVariables["fp-page-id"],
					pageType: metricVariables["fp-page-type"],
					siteId: metricVariables["fp-site-id"],
					linkIndex: Number(metrics?.["fp-link-index"]),
					linkName: metrics["fp-link-name"],
					linkUrl: metrics["fp-link-url"],
					linkId: metrics["fp-link-id"]
				});
			}
		});
	}

	getFormattedPlotData (props: GetFormattedPlotDataProps): FormattedPlotData {
		const { rawPlotData, fromDate, interval, maxInnerTickValues } = props;

		const isHourFilter = interval?.includes("h");

		let plotData: FormattedPlotData["plotData"] = [];

		if (isHourFilter) {
			const plotDataHours = rawPlotData?.map(rawData => rawData?.x?.getHours());
			const dataInBetween = [...(
				new Set([
					...getDayPartedHours(+1),
					...plotDataHours
				])
			)].sort((a, b) => a - b);

			plotData = dataInBetween.map(hour => {
				const formattedHour = getFormattedHour(hour);

				const existentData = rawPlotData
					?.find(rawData => getFormattedHour(rawData?.x?.getHours()) === formattedHour);

				const data = {
					x: formattedHour,
					y: existentData?.y || 0
				};

				return data;
			});
		} else {
			const todayDate = new Date();

			const dataInBetween = getDatesInBetween(fromDate, todayDate, +1);

			plotData = dataInBetween.map(date => {
				const formattedDate = toUTCDateString(date);

				const existentData = rawPlotData
					?.find(rawData => toUTCDateString(rawData.x) === formattedDate);

				const data = {
					x: formattedDate,
					y: existentData?.y || 0
				};

				return data;
			});
		}

		let tickValues: string[] = [];

		if (plotData.length > maxInnerTickValues) {
			tickValues = plotData
				.filter((_, index) => {
					const isValidIndex = (index % Math.floor(plotData.length / maxInnerTickValues)) === 0;
					const isFirstIndex = isFirstItem(index);
					const isLastIndex = isLastItem(index, plotData.length);

					const isValidTick = isValidIndex || isFirstIndex || isLastIndex;

					return isValidTick;
				})
				.map(data => data.x);
		} else {
			tickValues = plotData.map(data => data.x);
		}

		const plotDataValues = plotData?.map(data => data.y);

		const minMax = getMinMaxFromArray(plotDataValues);

		return {
			plotData,
			tickValues,
			yDomain: {
				max: minMax.max + 20,
				min: minMax.min
			}
		};
	}

	getMetricVariables (): MetricVariables {
		// eslint-disable-next-line
		const metrics = {} as any

		const dataLayerVariables = GoogleTagManagerService.dataLayerVariables;

		Object.entries(dataLayerVariables).forEach(([key, value]) => {
			const isMetricKey = this.isMetricKey(key);

			if (isMetricKey) {
				const newKey = this.getKeyWithoutPrefix(key);

				metrics[newKey] = value;
			}
		});

		return metrics;
	}

	private getEventMetrics = (event: MouseEvent): ElementMetrics => {
		let metricProps: ElementMetrics = {};

		const elements = event.composedPath() as HTMLElement[];

		const metricElements = elements.filter(element => !!element?.getAttribute?.(this.metricMainProperty));

		metricElements.forEach(metricElement => {
			const elementMetricProps = this.getElementMetrics(metricElement);

			metricProps = {
				...metricProps,
				...elementMetricProps
			};
		});

		return metricProps;
	}

	private getElementMetrics = (element: Element): ElementMetrics => {
		// eslint-disable-next-line
		const metricProps = {} as any

		const elementAttributes = element.getAttributeNames();

		elementAttributes.forEach((key) => {
			const isMetricKey = this.isMetricKey(key);

			if (isMetricKey) {
				const newKey = this.getKeyWithoutPrefix(key);
				const value = element.getAttribute(key);

				metricProps[newKey] = value;
			}
		});

		return metricProps;
	}

	/**
	 * A way to avoid duplicated props on elements.
	 * Ex:
	 *  - linkId becomes metrics-linkId.
	 */
	private addMetricPrefixToProps (props: object) {
		// eslint-disable-next-line
		const dataWithMetricPrefix = {} as any

		Object.entries(props).forEach(([key, value]) => {
			const newKey = this.getKeyWithPrefix(key);

			dataWithMetricPrefix[newKey] = value;
		});

		return dataWithMetricPrefix;
	}

	private getKeyWithoutPrefix (key: string): string {
		const keyWithoutPrefix = key.replace(this.metricPrefix, "");

		return keyWithoutPrefix;
	}

	private getKeyWithPrefix (key: string): string {
		const keyWithPrefix = `${this.metricPrefix}${key}`;

		return keyWithPrefix;
	}

	private isMetricKey (key: string): boolean {
		const isMetricKey = key.startsWith(this.metricPrefix);

		return isMetricKey;
	}
}

export default new MetricService();
