import React from "react";
import {
  ButtonBase,
  Typography,
  Link,
  Button,
  Grid,
  Fab,
} from "@material-ui/core";

import {
  FileCopyOutlined as CopyIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";

import { Portlet } from "@/components";

import { replaceSlugInUrlTemplate } from "@/utils/url";

import useOrganization from "@/hooks/useOrganization";
import useShare from "@/hooks/useShare";
import useStyles from "@/pages/SiteList/SiteListItem/styles";
import useCustomStyles from "@/styles/custom";

import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";

type SiteListItemProps = {
  onClick: () => void;
  url: string;
  slug: string;
  name: string;
};

const SiteListItem: React.FC<SiteListItemProps> = (props) => {
  const { onClick, url, slug, name } = props;

  const organization = useOrganization();
  const share = useShare();
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const handleCopySiteUrlClick = () => {
    amplitude.getInstance().logEvent("Link - Copiou link");
    const analytics = getAnalytics();
    logEvent(analytics, "Link - Copiou link");
    share.copy(url);
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Grid container className={classes.container}>
      <Grid component={ButtonBase} container>
        <Portlet
          elevation={1}
          onClick={handleClick}
          style={{
            height: "100%",
            border: "1px solid #CCCCCC",
            position: "relative",
            borderRadius: organization.theme.shape.borderRadius,
          }}
        >
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="body1" className={customClasses.inlineText}>
              {name}
            </Typography>

            <Link
              href={url}
              className={customClasses.inlineText}
              target="_blank"
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                event.stopPropagation()
              }
            >
              {replaceSlugInUrlTemplate(
                organization?.data?.changeSlugHost,
                slug
              )}
            </Link>
          </Grid>

          <Fab size="small" className={classes.editSiteIcon}>
            <EditIcon color="primary" />
          </Fab>
        </Portlet>
      </Grid>

      <Button
        variant="text"
        onClick={handleCopySiteUrlClick}
        className={classes.button}
        endIcon={<CopyIcon fontSize="small" />}
      >
        Copiar Link
      </Button>
    </Grid>
  );
};

export default SiteListItem;
