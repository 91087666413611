import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2, 3),
		backgroundColor: colors.greyScale[11],
		height: "100%"
	},
	content: {
		width: "100%"
	}
}));

export default useStyles;
