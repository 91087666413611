import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

import {
	Portlet,
	Divider
} from "@/components";

const Account = () => (
	<Grid
		container
		direction="column"
	>
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<Grid container>
				<Portlet
					elevation={1}
				>
					<Skeleton
						width="200px"
						height={40}
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						width="100%"
						height={48}
					/>

					<Skeleton
						width="100%"
						height={48}
					/>

					<Skeleton
						width="100%"
						height={48}
					/>
				</Portlet>
			</Grid>
		</Grid>
	</Grid>
);

export default Account;
