import React from "react";
import {
	ButtonBase,
	Typography,
	Grid,
	Button
} from "@material-ui/core";

import { Portlet, Divider } from "@/components";

import useOrganization from "@/hooks/useOrganization";
import useStyles from "@/components/SetupListItem/styles";

type SetupListItemProps = {
	onClick: () => void
	title: string
	description: string
	buttonText?: string
	logoSrc?: string
}

const SetupListItem: React.FC<SetupListItemProps> = (props) => {
	const { onClick, title, description, buttonText, logoSrc } = props;

	const organization = useOrganization();
	const classes = useStyles();

	const handleClick = () => {
		onClick();
	};

	return (
		<Grid
			container
		>
			<Grid
				component={ButtonBase}
				container
			>
				<Portlet
					elevation={1}
					onClick={handleClick}
					style={{
						height: "100%",
						border: "1px solid #CCCCCC",
						position: "relative",
						borderRadius: organization.theme.shape.borderRadius,
						padding: 24
					}}
				>
					{logoSrc && (
						<>
							<Grid
								container
								justify="flex-start"
							>
								<img
									alt="Logo"
									src={logoSrc}
									className={classes.logo}
								/>
							</Grid>

							<Divider orientation="horizontal" size={2} />
						</>
					)}
					<Typography
						variant="body1"
						className={classes.title}
						align="left"
					>
						{title}
					</Typography>

					<Typography
						variant="body1"
						className={classes.description}
						align="left"
					>
						{description}
					</Typography>

					{buttonText && (
						<>
							<Divider orientation="horizontal" size={2} />

							<Grid
								container
								justify="flex-start"
							>
								<Button
									onClick={handleClick}
									disableElevation
									variant="contained"
									color="primary"
								>
									{buttonText}
								</Button>
							</Grid>
						</>
					)}
				</Portlet>
			</Grid>
		</Grid>
	);
};

export default SetupListItem;
