/* eslint-disable space-before-function-paren */
class StorageService {
  // eslint-disable-next-line
  set(key: string, data: any): void {
    const stringData = JSON.stringify(data);

    const encodedData = btoa(stringData);

    localStorage.setItem(key, encodedData);
  }

  get<ExpectedData>(key: string): ExpectedData | null {
    const encodedData = localStorage.getItem(key);
    if (!encodedData) {
      return null;
    }
    try {
      const stringData = atob(encodedData);

      const data: ExpectedData = JSON.parse(stringData);

      return data;
    } catch {
      return null;
    }
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }
}

export default new StorageService();
