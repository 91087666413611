import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	selectHelperText: {
		padding: theme.spacing(0, 2)
	},
	form: {
		display: "flex",
		width: "100%",
		flexDirection: "column"
	}
}));

export default useStyles;
