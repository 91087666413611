import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  Link as MaterialLink,
  Button,
} from "@material-ui/core";
import {
  AccountCircleOutlined as AccountIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";

import AuthService from "@/services/Auth";

import useOrganization from "@/hooks/useOrganization";
import { useGlobalStateStore } from "@/store/GlobalState";
import useStyles, {
  HEADER_HEIGHT,
} from "@/components/OrganizationHeader/styles";
import useDidMount from "@/hooks/useDidMount";

import { AppContainer } from "@/components";

import appConfig from "@/config/app";

import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";
import crownIllustration from "@/assets/crown.svg";

const OrganizationHeader = () => {
  const organization = useOrganization();
  const classes = useStyles();
  const globalStateStore = useGlobalStateStore();

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);
  const menuOpened = Boolean(menuAnchorElement);

  const getLogo = () => {
    return organization.data.headerLogo;
  };

  const setup = () => {
    const appContainer = document.getElementById(appConfig.appContainerId);

    if (appContainer) {
      appContainer.style.paddingTop = `${HEADER_HEIGHT}px`;
    }
  };

  const handleOpenMenu = (targetElement: HTMLElement) => {
    setMenuAnchorElement(targetElement);
  };

  const handleCloseMenu = (menuItemTitle?: string) => {
    if (menuItemTitle === "Crie sua loja online completa") {
      amplitude
        .getInstance()
        .logEvent("Upgrade - Clicou em 'Crie sua loja online completa'");
      const analytics = getAnalytics();
      logEvent(
        analytics,
        "Upgrade - Clicou em 'Crie sua loja online completa'"
      );
    }
    setMenuAnchorElement(null);
  };

  const getMenuIcon = () => {
    if (organization.data.type === "bagy") {
      return <MenuIcon />;
    } else {
      return <AccountIcon />;
    }
  };

  useDidMount(() => {
    setup();
  });

  return (
    <AppContainer
      id="app-header-container"
      ContainerProps={{
        className: classes.headerContainer,
      }}
      ContentProps={{
        className: classes.headerContent,
      }}
    >
      <Link to="/site/list" className={classes.logoImg}>
        <img src={getLogo()} alt="logo" className={classes.logoImg} />
      </Link>

      {globalStateStore?.customer?.id && (
        <>
          <IconButton
            className={classes.menuButton}
            onClick={({ target }) => handleOpenMenu(target as HTMLElement)}
          >
            {getMenuIcon()}
          </IconButton>

          <Menu
            anchorEl={menuAnchorElement}
            open={menuOpened}
            onClose={() => handleCloseMenu()}
            classes={{
              paper: classes.menuPaper,
            }}
          >
            {organization.data.menuItems.map(
              (menuItem) =>
                menuItem.title === "Crie sua loja online completa" && (
                  <Button
                    className={classes.lowerCaseButton}
                    variant="contained"
                    color="primary"
                    key={menuItem.title}
                    onClick={() => handleCloseMenu(menuItem.title)}
                    {...(menuItem.type === "link"
                      ? {
                          href: menuItem.value,
                          target: "_blank",
                        }
                      : {
                          component: Link,
                          to: menuItem.value,
                        })}
                  >
                    <img
                      src={crownIllustration}
                      alt="Agradecimento"
                      className={classes.crownImage}
                    />
                    {menuItem.title}
                  </Button>
                )
            )}
            <MenuItem
              onClick={() => handleCloseMenu()}
              component={Link}
              to="/site/list"
            >
              Início
            </MenuItem>

            <MenuItem
              onClick={() => handleCloseMenu()}
              component={Link}
              to="/customer/account"
            >
              Minha conta
            </MenuItem>
            {organization.data.menuItems.map(
              (menuItem) =>
                menuItem.title !== "Crie sua loja online completa" && (
                  <MenuItem
                    key={menuItem.title}
                    onClick={() => handleCloseMenu(menuItem.title)}
                    {...(menuItem.type === "link"
                      ? {
                          component: MaterialLink,
                          href: menuItem.value,
                          target: "_blank",
                        }
                      : {
                          component: Link,
                          to: menuItem.value,
                        })}
                  >
                    {menuItem.title}
                  </MenuItem>
                )
            )}

            <MenuItem
              onClick={() => AuthService.logout(organization.data.signInRoute)}
              component={MaterialLink}
            >
              Sair
            </MenuItem>
          </Menu>
        </>
      )}
    </AppContainer>
  );
};

export default OrganizationHeader;
