import React from "react";
import { Typography, TypographyTypeMap } from "@material-ui/core";

import useStyles from "@/@fp/components/Paragraph/styles";

type ParagraphProps = TypographyTypeMap["props"] & {
	style?: React.CSSProperties
	className?: string
	component?: React.ElementType
}

const Paragraph: React.FC<ParagraphProps> = (props) => {
	const classes = useStyles();

	const { children, className } = props;

	return (
		<Typography
			{...props}
			variant="body1"
			className={`${classes.paragraph} ${className}`}
		>
			{children}
		</Typography>
	);
};

export default Paragraph;
