import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: "bold",
		fontSize: 16,
		color: theme.palette.primary.main
	},
	description: {
		fontSize: 12,
		color: "#000000",
		lineHeight: "16px"
	},
	logo: {
		height: 40
	}
}));

export default useStyles;
