import React from "react";

import ImageSelect from "@/components/ImageSelect";

import useStyles from "@/components/ButtonSelect/styles";

import { serializeJSON, deserializeJSON } from "@/utils/json";

import outlinedSquare from "@/assets/buttons/outlined-square.svg";
import outlinedRound from "@/assets/buttons/outlined-round.svg";
import outlinedRounder from "@/assets/buttons/outlined-rounder.svg";
import containedSquare from "@/assets/buttons/contained-square.svg";
import containedRound from "@/assets/buttons/contained-round.svg";
import containedRounder from "@/assets/buttons/contained-rounder.svg";

type DefaultButton = {
	variant: "outlined" | "contained"
	borderRadius: number | string
	picture: string
}

export type Button = {
	variant: "outlined" | "contained"
	borderRadius: number | string
}

type ButtonSelectProps = {
	value: Button
	onChange?: (button: Button) => void
}

type DefaultButtons = Array<DefaultButton>

const DEFAULT_BUTTONS: DefaultButtons = [
	{ borderRadius: "0px", variant: "contained", picture: containedSquare },
	{ borderRadius: "8px", variant: "contained", picture: containedRound },
	{ borderRadius: "28px", variant: "contained", picture: containedRounder },
	{ borderRadius: "0px", variant: "outlined", picture: outlinedSquare },
	{ borderRadius: "8px", variant: "outlined", picture: outlinedRound },
	{ borderRadius: "28px", variant: "outlined", picture: outlinedRounder }
];

const ButtonSelect: React.FC<ButtonSelectProps> = (props) => {
	const { value, onChange } = props;

	const classes = useStyles();

	const handleChange = (serializedValue: string) => {
		const button = deserializeJSON<Button>(serializedValue);

		if (onChange) {
			onChange(button);
		}
	};

	const serializedValue = serializeJSON(value);

	return (
		<ImageSelect
			value={serializedValue}
			items={(
				DEFAULT_BUTTONS.map(defaultButton => {
					const { picture, ...button } = defaultButton;

					return {
						value: serializeJSON(button),
						imageSrc: picture
					};
				})
			)}
			onChange={handleChange}
			buttonStyle={{
				boxShadow: "none",
				borderRadius: "0px"
			}}
			classes={{
				selectedButton: classes.selectedButton,
				selectedIcon: classes.selectedIcon,
				buttonContainer: classes.buttonContainer
			}}
		/>
	);
};

export default ButtonSelect;
