import { makeStyles } from "@material-ui/core";

type UseStylesProps = {
	lateralSpacing?: number
	topSpacing?: number
}

const useStyles = makeStyles(theme => ({
	container: {
		padding: (props: UseStylesProps) => `0px ${props.lateralSpacing}px`,
		paddingTop: (props: UseStylesProps) => `${props.topSpacing}px`
	},
	text: {
		width: "100%",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "14px",
		lineHeight: "24px",
		color: theme.palette.text.secondary,
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.default
	}
}));

export default useStyles;
