import { makeStyles } from "@material-ui/core";

export const PAGE_CARD_ASPECT_RATIO = 1.44;

const useStyles = makeStyles({
	card: {
		width: "100%",
		height: 0,
		paddingTop: `${PAGE_CARD_ASPECT_RATIO * 100}%`,
		backgroundColor: "#FFFFFF",
		borderRadius: 8,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	}
});

export default useStyles;
