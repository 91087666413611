import React from "react";

import { Grid, Container } from "@material-ui/core";

import useStyles from "@/components/AppContainer/styles";

type AppContainerProps = {
	id: string
	ContainerProps?: {
		className?: string
	}
	ContentProps?: {
		className?: string
	}
}

const AppContainer: React.FC<AppContainerProps> = (props) => {
	const {
		id,
		children,
		ContainerProps,
		ContentProps
	} = props;

	const classes = useStyles();

	return (
		<Grid
			container
			justify="center"
			className={`${classes.container} ${ContainerProps?.className}`}
		>
			<Container
				maxWidth="xs"
				disableGutters
				id={id}
				className={`${classes.content} ${ContentProps?.className}`}
			>
				{children as React.ReactChild}
			</Container>
		</Grid>
	);
};

export default AppContainer;
