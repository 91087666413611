import React from "react";
import { Typography } from "@material-ui/core";

import ImageSelect from "@/components/ImageSelect";

import useStyles from "@/components/FontSelect/styles";

import { serializeJSON, deserializeJSON } from "@/utils/json";

type Font = {
  fontWeight: React.CSSProperties["fontWeight"];
  fontStyle: string;
  fontFamily: string;
};

type DefaultFont = Font;

type FontSelectProps = {
  value: Font;
  onChange?: (color: Font) => void;
};

type DefaultFonts = Array<DefaultFont>;

export const DEFAULT_FONTS: DefaultFonts = [
  { fontFamily: "Montserrat", fontWeight: 600, fontStyle: "normal" },
  { fontFamily: "Montserrat", fontWeight: 200, fontStyle: "normal" },
  { fontFamily: "Montserrat", fontWeight: 400, fontStyle: "italic" },
  { fontFamily: "Montserrat", fontWeight: 700, fontStyle: "italic" },
  { fontFamily: "Work Sans", fontWeight: 600, fontStyle: "normal" },
  { fontFamily: "Work Sans", fontWeight: 200, fontStyle: "normal" },
  { fontFamily: "Work Sans", fontWeight: 400, fontStyle: "italic" },
  { fontFamily: "Work Sans", fontWeight: 700, fontStyle: "italic" },
  { fontFamily: "Vollkorn SC", fontWeight: 700, fontStyle: "normal" },
  { fontFamily: "Vollkorn SC", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Quicksand", fontWeight: 700, fontStyle: "normal" },
  { fontFamily: "Quicksand", fontWeight: 300, fontStyle: "normal" },
  { fontFamily: "PT Sans Caption", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Raleway", fontWeight: 300, fontStyle: "italic" },
  { fontFamily: "Raleway", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Source Code Pro", fontWeight: 200, fontStyle: "normal" },
  { fontFamily: "Bangers", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Staatliches", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Nova Flat", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Shadows Into Light", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Dancing Script", fontWeight: 400, fontStyle: "normal" },
  { fontFamily: "Indie Flower", fontWeight: 400, fontStyle: "normal" },
];

const FontSelect: React.FC<FontSelectProps> = (props) => {
  const { value, onChange } = props;

  const typographyVerticalSpacing = 22;

  const classes = useStyles({ typographyVerticalSpacing });

  const handleChange = (serializedValue: string) => {
    const font = deserializeJSON<Font>(serializedValue);

    if (onChange) {
      onChange(font);
    }
  };

  const serializedValue = serializeJSON(value);

  if (value) {
    const valueHasDefault = DEFAULT_FONTS.find(
      (defaultFont) => serializeJSON(defaultFont) === serializedValue
    );

    if (!valueHasDefault) {
      DEFAULT_FONTS.unshift(value);
    }
  }

  return (
    <ImageSelect
      value={serializedValue}
      items={DEFAULT_FONTS.map((defaultFont) => ({
        value: serializeJSON(defaultFont),
      }))}
      render={(item) => {
        const font = deserializeJSON<Font>(item.value);

        return (
          <Typography
            className={classes.fontExample}
            style={{
              fontWeight: font.fontWeight,
              fontFamily: font.fontFamily,
              fontStyle: font.fontStyle,
            }}
          >
            Aa
          </Typography>
        );
      }}
      onChange={handleChange}
      buttonStyle={{
        boxShadow: "none",
        marginBottom: typographyVerticalSpacing,
        width: 40,
      }}
      classes={{
        selectedButton: classes.selectedButton,
        selectedIcon: classes.selectedIcon,
        buttonContainer: classes.buttonContainer,
        buttonGroupContainer: classes.buttonGroupContainer,
      }}
    />
  );
};

export default FontSelect;
