import React, { useState } from "react";
import {
	Grid,
	Avatar
} from "@material-ui/core";

import {
	Divider
} from "@/components";

import {
	Dialog,
	Title
} from "@/@fp/components";

import useStyles from "@/@fp/components/YoutubeVideo/styles";

import { ReactComponent as YoutubeIcon } from "@/assets/icons/youtube-2.svg";
import videoMockupImg from "@/assets/video_mockup.png";

type YoutubeVideoProps = {
	thumbnailUrl: string
	avatarUrl: string
	title: string
	videoId: string
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = (props) => {
	const {
		thumbnailUrl,
		title,
		videoId,
		avatarUrl
	} = props;

	const classes = useStyles();

	const [youtubeVideoDialogOpened, setYoutubeVideoDialogOpened] = useState(false);

	const handleOpenYoutubeVideoDialog = () => {
		setYoutubeVideoDialogOpened(true);
	};

	const handleCloseYoutubeVideoDialog = () => {
		setYoutubeVideoDialogOpened(false);
	};

	return (
		<>
			<Dialog
				opened={youtubeVideoDialogOpened}
				onClose={handleCloseYoutubeVideoDialog}
			>
				<Grid
					container
					className={classes.videoContainer}
				>
					<iframe
						src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
						title={title}
						className={classes.videoIframe}
						style={{ backgroundImage: `url(${videoMockupImg})` }}
						{...({
							allowfullscreen: "allowfullscreen",
							mozallowfullscreen: "mozallowfullscreen",
							msallowfullscreen: "msallowfullscreen",
							oallowfullscreen: "oallowfullscreen",
							webkitallowfullscreen: "webkitallowfullscreen"
						})}
					/>
				</Grid>
			</Dialog>

			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				onClick={handleOpenYoutubeVideoDialog}
			>
				<Grid
					container
					className={classes.videoContainer}
					style={{
						backgroundImage: `url(${thumbnailUrl})`
					}}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						className={classes.youtubeIconContainer}
					>
						<YoutubeIcon className={classes.youtubeIcon} />
					</Grid>

					<Grid
						container
						alignItems="center"
						justify="flex-start"
						className={classes.youtubeNavbar}
					>
						<Avatar
							src={avatarUrl}
						/>

						<Divider orientation="vertical" size={1} />

						<Title
							className={classes.youtubeNavbarTitle}
							align="left"
						>
							{title}
						</Title>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default YoutubeVideo;
