import React from "react";
import { Route } from "react-router-dom";

import LoginByToken from "@/pages/LoginByToken";
import Login from "@/pages/Login";

import RecoveryRequest from "@/pages/Recovery/Request";
import RecoveryReset from "@/pages/Recovery/Reset";
import RecoveryGenerate from "@/pages/Recovery/Generate";

import FP from "@/pages/FP";

export const FPPublicRoutes = [
	<Route
		exact
		key="fp-subdomain"
		path="/"
		component={FP}
	/>,
	<Route
		exact
		key="fp-slug"
		path="/:slug"
		component={FP}
	/>
];

export const CPPublicRoutes = [
	<Route
		exact
		key="login-by-token"
		path="/sites/:siteId/login/:loginToken"
		component={LoginByToken}
	/>,
	<Route
		exact
		key="login"
		path="/login"
		component={Login}
	/>,
	<Route
		exact
		key="recovery-request"
		path="/recovery/request"
		component={RecoveryRequest}
	/>,
	<Route
		exact
		key="recovery-reset"
		path="/recovery/reset"
		component={RecoveryReset}
	/>,
	<Route
		exact
		key="recovery-generate"
		path="/recovery/generate"
		component={RecoveryGenerate}
	/>
];
