import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";
import crownIllustration from "@/assets/crown.svg";

import useStyles from "./styles";

type PurchaseBagyButtonProps = {
  url: string;
  type: string;
};

const PurchaseBagyButton: React.FC<PurchaseBagyButtonProps> = (props) => {
  const handleClickButton = () => {
    amplitude
      .getInstance()
      .logEvent("Upgrade - Clicou em 'Crie sua loja online completa'");
    const analytics = getAnalytics();
    logEvent(analytics, "Upgrade - Clicou em 'Crie sua loja online completa'");
  };

  const { url, type } = props;
  const classes = useStyles();
  return (
    <Button
      className={classes.lowerCaseButton}
      variant="contained"
      color="primary"
      onClick={() => handleClickButton()}
      {...(type === "link"
        ? {
            href: url,
            target: "_blank",
          }
        : {
            component: Link,
            to: { url },
          })}
    >
      <img
        src={crownIllustration}
        alt="Agradecimento"
        className={classes.crownImage}
      />
      Crie sua loja online completa
    </Button>
  );
};
export default PurchaseBagyButton;
