import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    verticalAlign: "bottom",
    marginLeft: theme.spacing(1),
  },
  deleteButton: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
