import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	limitedTextSizeStyles: {
		maxWidth: 120,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden"
	},
	cardItemCoverContainer: {
		maxWidth: 108
	},
	cardItemCover: {
		paddingBottom: "100%",
		height: 0,
		width: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
		borderRadius: theme.spacing(1),
		boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.08)"
	},
	cardInfoTitle: {
		color: colors.greyScale[1],
		marginBottom: theme.spacing(1)
	},
	cardInfoDescription: {
		textTransform: "none",
		color: colors.greyScale[3],
		fontSize: 14,
		marginBottom: theme.spacing(1)
	},
	cardInfoCaption: {
		fontSize: 14,
		color: colors.greyScale[4],
		marginBottom: theme.spacing(1)
	},
	cardItemInfoContainer: {
		flex: 1,
		marginLeft: theme.spacing(2)
	}
}));

export default useStyles;
