import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	buttonContainer: {
		position: "relative"
	},
	button: {
		width: "100%",
		borderRadius: theme.spacing(1),
		boxShadow: theme.shadows[1],
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	selectedButton: {
		border: `1px solid ${theme.palette.primary.main}`
	},
	img: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		borderRadius: theme.spacing(1)
	},
	selectedIcon: {
		position: "absolute",
		bottom: -10,
		right: -10,
		width: 30,
		height: 30,
		minHeight: 30
	},
	label: {
		color: colors.greyScale[1],
		marginTop: theme.spacing(1.5)
	}
}));

export default useStyles;
