import React from "react";

import {
	Grid,
	IconButton,
	Typography
} from "@material-ui/core";
import {
	ExpandLessOutlined as ArrowUpIcon,
	ExpandMoreOutlined as ArrowDownIcon,
	ArrowBackIosOutlined as ArrowLeftIcon,
	ArrowForwardIosOutlined as ArrowRightIcon
} from "@material-ui/icons";

import {
	isFirstItem
} from "@/utils/array";

import useCustomStyles from "@/styles/custom";

type OrderActionGroupProps = {
	index: number
	text: string
	orientation: "horizontal" | "vertical"
	hideActionButtons?: boolean
	onFirstOrderActionClick: () => void
	onSecondOrderActionClick: () => void
	firstOrderActionClassName?: string
	secondOrderActionClassName?: string
}

const OrderActionGroup: React.FC<OrderActionGroupProps> = (props) => {
	const {
		text,
		index,
		orientation,
		onFirstOrderActionClick,
		onSecondOrderActionClick,
		hideActionButtons,
		firstOrderActionClassName,
		secondOrderActionClassName
	} = props;

	const customClasses = useCustomStyles();

	return (
		<Grid>
			{!hideActionButtons && (
				<IconButton
					className={`${isFirstItem(index) && customClasses.hiddenElement} ${customClasses.arrowIcon} ${firstOrderActionClassName}`}
					onClick={onFirstOrderActionClick}
				>
					{orientation === "vertical" ? (
						<ArrowUpIcon
							fontSize="small"
						/>
					) : (
						<ArrowLeftIcon
							fontSize="small"
						/>
					)}
				</IconButton>
			)}

			<Typography variant="caption">
				{text}
			</Typography>

			{!hideActionButtons && (
				<IconButton
					className={`${secondOrderActionClassName} ${customClasses.arrowIcon}`}
					onClick={onSecondOrderActionClick}
				>
					{orientation === "vertical" ? (
						<ArrowDownIcon
							fontSize="small"
						/>
					) : (
						<ArrowRightIcon
							fontSize="small"
						/>
					)}
				</IconButton>
			)}
		</Grid>
	);
};

export default OrderActionGroup;
