import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	siteMetricsContainer: {
		height: "100%"
	},
	upperCasedText: {
		textTransform: "uppercase"
	},
	plotHint: {
		backgroundColor: colors.greyScale[11],
		padding: theme.spacing(1),
		boxShadow: theme.shadows[1],
		borderRadius: theme.spacing(0.5)
	},
	listItem: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
}));

export default useStyles;
