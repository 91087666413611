import React from "react";
import { Route } from "react-router-dom";

import SignUp from "@/@organizations/Bagy/pages/SignUp";
import SignIn from "@/@organizations/Bagy/pages/SignIn";

import RecoveryRequest from "@/@organizations/Bagy/pages/Recovery/Request";
import RecoveryReset from "@/@organizations/Bagy/pages/Recovery/Reset";

import { PrivateCPRoutes } from "@/@organizations/Bagy/routes/private";

export const PublicCPRoutes = [
	<Route
		exact
		key="bagy-signup"
		path="/bagy/signup"
		component={SignUp}
	/>,
	<Route
		exact
		key="bagy-signin"
		path="/bagy/signin"
		component={SignIn}
	/>,
	<Route
		exact
		key="bagy-recovery-request"
		path="/bagy/recovery/request"
		component={RecoveryRequest}
	/>,
	<Route
		exact
		key="bagy-recovery-reset"
		path="/bagy/recovery/reset"
		component={RecoveryReset}
	/>,
	...PrivateCPRoutes
];
