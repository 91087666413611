import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid, makeStyles, Container } from "@material-ui/core";

import { Divider } from "@/components";

const useStyles = makeStyles({
	container: {
		height: "100%"
	},
	content: {
		flex: 1
	}
});

const DashboardSkeleton = () => {
	const classes = useStyles();

	return (
		<>
			<Grid
				container
				alignItems="center"
				direction="column"
				className={classes.container}
			>
				<Skeleton variant="rect" width="100%" height={88} animation="wave" />

				<Divider orientation="horizontal" size={3} />

				<Container>
					<Skeleton
						variant="rect"
						width="100%"
						height={20}
						animation="wave"
					/>
				</Container>

				<Divider orientation="horizontal" size={3} />

				<Container className={classes.content}>
					<Skeleton
						variant="rect"
						width="100%"
						height="100%"
						animation="wave"
					/>
				</Container>
			</Grid>
		</>
	);
};

export default DashboardSkeleton;
