import React, { useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Typography, Grid, Fab, Button } from "@material-ui/core";
import {
  AddOutlined as AddOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";

import { Divider, Loading, PageCard, OrderActionGroup } from "@/components";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { moveItem, moveItemElement } from "@/utils/array";

import useStyles from "@/pages/PageList/styles";

import useDidMount from "@/hooks/useDidMount";

import { PageType } from "@/@fp/protocols/Page";

import PageListSkeleton from "@/skeletons/PageList";

import MetricsEditDrawer from "@/pages/PageList/MetricsEditDrawer";
import SiteSettingsEditDrawer from "@/pages/PageList/SiteSettingsEditDrawer";

type Page = {
  id: number;
  order: number;
  type: PageType;
  active: boolean;
  coverUrl: string;
};

const PageList = () => {
  const classes = useStyles();
  const history = useHistory();

  const { siteId } = useParams<{ siteId: string }>();

  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState<Page[]>([]);

  const getPageList = async () => {
    try {
      const response = await ApiService.get("/site/pages", {
        params: {
          siteId,
        },
      });

      setPages(response.data.pages);
    } catch (error) {
      ErrorHandlerService.handle(error);
    }
  };

  const handleAddPage = () => {
    history.push(`/sites/${siteId}/template-page-categories`);
  };

  const handleOrder = async (fromIndex: number, toIndex: number) => {
    await moveItemElement(`page-${fromIndex}`, `page-${toIndex}`);

    const orderedPages = moveItem<Page[]>(pages, fromIndex, toIndex) || [];

    setPages(orderedPages);

    try {
      const order = orderedPages.map((orderedPage, index) => ({
        pageId: orderedPage.id,
        order: index,
      }));

      await ApiService.put("/site/pages/order", {
        order,
      });
    } catch (error) {
      ErrorHandlerService.handle(error);
    }
  };

  const getData = async () => {
    await getPageList();

    setLoading(false);
  };

  useDidMount(() => {
    getData();
  });

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h4">Páginas do link</Typography>

        <Grid container justify="flex-end" className={classes.iconButtonGroup}>
          <MetricsEditDrawer siteId={+siteId} />

          <Divider orientation="vertical" size={1} />

          <SiteSettingsEditDrawer siteId={+siteId} />
        </Grid>
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Loading loading={loading} customLoadingElement={<PageListSkeleton />}>
        <>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <PageCard
                style={{
                  position: "relative",
                }}
              >
                <Grid container className={classes.buttonContainer}>
                  <Button fullWidth color="primary" onClick={handleAddPage}>
                    <Grid container alignItems="center" direction="column">
                      <AddOutlinedIcon fontSize="large" />
                      <Typography
                        className={classes.addPageButtonText}
                        color="primary"
                      >
                        Adicionar
                        <br />
                        página
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              </PageCard>
            </Grid>

            {pages.map((page, index) => (
              <Grid
                key={index}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className={classes.cardGridItem}
                id={`page-${index}`}
              >
                <Link to={`/sites/${siteId}/pages/${page.id}/edit`}>
                  <PageCard
                    backgroundPictureSrc={page.coverUrl}
                    style={{
                      ...(!page.active
                        ? {
                            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${page.coverUrl})`,
                          }
                        : {}),
                    }}
                  >
                    <Fab size="small" className={classes.editPageIcon}>
                      <EditOutlinedIcon color="primary" />
                    </Fab>
                  </PageCard>
                </Link>

                {page.active ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.pageTextContainer}
                  >
                    <OrderActionGroup
                      index={index}
                      text={`página ${index + 1}`}
                      orientation="horizontal"
                      onFirstOrderActionClick={() =>
                        handleOrder(index, index - 1)
                      }
                      onSecondOrderActionClick={() =>
                        handleOrder(index, index + 1)
                      }
                    />
                  </Grid>
                ) : (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.pageTextContainer}
                  >
                    <Typography variant="caption">página desativada</Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      </Loading>
    </>
  );
};

export default PageList;
