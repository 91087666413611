import React, { useState } from "react";
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  HorizontalGridLines,
  VerticalBarSeriesPoint,
  Hint,
} from "react-vis";
import {
  Typography,
  Grid,
  Fab,
  List,
  ListItem,
  Select,
  MenuItem,
  Divider as MaterialDivider,
} from "@material-ui/core";
import { AssessmentOutlined as MetricsIcon } from "@material-ui/icons";

import { Divider, Loading, EditDrawer } from "@/components";

import MetricService, { Metrics, MetricFilters } from "@/services/Metric";

import { getWordBasedOnCount } from "@/utils/grammar";
import { todayMinusSomeDays } from "@/utils/date";

import MetricsEditDrawerSkeleton from "@/skeletons/MetricsEditDrawer";

import useStyles from "@/pages/PageList/MetricsEditDrawer/styles";
import useDidMount from "@/hooks/useDidMount";
import useCustomStyles from "@/styles/custom";
import useOrganization from "@/hooks/useOrganization";

import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";

type SiteMetricFilters = {
  fromDays: number;
  device: MetricFilters["device"] | "all";
  interval: MetricFilters["interval"];
};

type MetricsEditDrawerProps = {
  siteId: number;
};

const MetricsEditDrawer: React.FC<MetricsEditDrawerProps> = (props) => {
  const { siteId } = props;

  const classes = useStyles();
  const customClasses = useCustomStyles();
  const organization = useOrganization();

  const [siteMetricsHoverData, setSiteMetricsHoverData] =
    useState<VerticalBarSeriesPoint | null>(null);
  const [siteMetrics, setSiteMetrics] = useState<Metrics>({} as Metrics);
  const [loadingSiteMetrics, setLoadingSiteMetrics] = useState(true);
  const [siteMetricFilters, setSiteMetricFilters] = useState<SiteMetricFilters>(
    {
      fromDays: 0,
      device: "all",
      interval: "1h",
    }
  );

  const rawPlotData =
    siteMetrics?.site?.access?.daily?.map((dailyAccess) => ({
      x: new Date(dailyAccess.date),
      y: dailyAccess.count,
    })) || [];

  const formattedPlotData = MetricService.getFormattedPlotData({
    rawPlotData,
    fromDate: todayMinusSomeDays(siteMetricFilters.fromDays),
    interval: siteMetricFilters?.interval,
    maxInnerTickValues: 3,
  });

  const handleChangeBarSeriesHoverData = (
    data: VerticalBarSeriesPoint | null
  ) => {
    setSiteMetricsHoverData(data);
  };

  const getSiteMetrics = async (filters: Partial<SiteMetricFilters>) => {
    setLoadingSiteMetrics(true);

    const newFilters = {
      ...siteMetricFilters,
      ...filters,
    };

    newFilters.interval = newFilters.fromDays === 0 ? "1h" : "1d";

    setSiteMetricFilters(newFilters);

    const fromDate = todayMinusSomeDays(newFilters.fromDays);

    const metrics = await MetricService.getSiteMetrics(+siteId, {
      fromDate,
      device: newFilters.device === "all" ? undefined : newFilters.device,
      interval: newFilters.interval,
    });

    if (metrics) {
      setSiteMetrics(metrics);
    }

    setLoadingSiteMetrics(false);
  };

  const getData = async () => {
    await getSiteMetrics(siteMetricFilters);
  };

  useDidMount(() => {
    getData();
  });

  const logEvents = () => {
    amplitude.getInstance().logEvent("Habit - Visualizou Analytics");
    const analytics = getAnalytics();
    logEvent(analytics, "Habit - Visualizou Analytics");
  };

  return (
    <EditDrawer
      title="Relatórios"
      pureChildren
      hideSaveButton
      onOpen={() => logEvents()}
      formComponent={
        <Grid
          container
          direction="column"
          className={classes.siteMetricsContainer}
        >
          <Typography variant="h4" color="textPrimary">
            Filtros
          </Typography>

          <Divider orientation="horizontal" size={3} />

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Select
                defaultValue={siteMetricFilters.fromDays}
                variant="outlined"
                value={siteMetricFilters.fromDays}
                onChange={({ target }) =>
                  getSiteMetrics({ fromDays: target.value as number })
                }
                className={customClasses.select}
                inputProps={{ className: customClasses.selectInput }}
                fullWidth
              >
                <MenuItem value={0}>Hoje</MenuItem>
                <MenuItem value={7}>Últimos 7 dias</MenuItem>
                <MenuItem value={15}>Últimos 15 dias</MenuItem>
                <MenuItem value={30}>Últimos 30 dias</MenuItem>
                <MenuItem value={60}>Últimos 60 dias</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Select
                defaultValue={siteMetricFilters.device}
                variant="outlined"
                value={siteMetricFilters.device}
                onChange={({ target }) =>
                  getSiteMetrics({
                    device: target.value as SiteMetricFilters["device"],
                  })
                }
                className={customClasses.select}
                inputProps={{ className: customClasses.selectInput }}
                fullWidth
              >
                <MenuItem value="all">Todos os dispositivos</MenuItem>
                <MenuItem value="Desktop">Desktop</MenuItem>
                <MenuItem value="Mobile">Mobile</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Divider orientation="horizontal" size={4} />

          <MaterialDivider light />

          <Divider orientation="horizontal" size={4} />

          <Loading
            loading={loadingSiteMetrics}
            customLoadingElement={<MetricsEditDrawerSkeleton />}
          >
            <Grid>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Typography variant="h4" color="textPrimary">
                    {getWordBasedOnCount(
                      "Acesso",
                      siteMetrics?.site?.access?.all
                    )}
                  </Typography>

                  <Divider orientation="horizontal" size={1} />

                  <Typography variant="overline" color="textPrimary">
                    {siteMetrics?.site?.access?.all}{" "}
                    {getWordBasedOnCount(
                      "acesso",
                      siteMetrics?.site?.access?.all
                    )}
                  </Typography>
                </Grid>

                <Divider orientation="horizontal" size={3} />

                <FlexibleWidthXYPlot
                  height={300}
                  xType="ordinal"
                  yDomain={[
                    formattedPlotData?.yDomain?.min,
                    formattedPlotData?.yDomain?.max,
                  ]}
                >
                  <XAxis tickValues={formattedPlotData?.tickValues} />
                  <YAxis />

                  <HorizontalGridLines />

                  <VerticalBarSeries
                    color={organization.colors.palette.primary}
                    data={formattedPlotData?.plotData}
                    barWidth={0.7}
                    onValueMouseOver={handleChangeBarSeriesHoverData}
                    onValueClick={handleChangeBarSeriesHoverData}
                    onValueMouseOut={() => handleChangeBarSeriesHoverData(null)}
                  />

                  {siteMetricsHoverData && (
                    <Hint value={siteMetricsHoverData}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={classes.plotHint}
                      >
                        <Typography variant="overline" color="textPrimary">
                          {siteMetricsHoverData.x}
                        </Typography>

                        <Divider orientation="horizontal" size={0.5} />

                        <Typography variant="overline" color="textPrimary">
                          <b>Acessos:</b> {siteMetricsHoverData.y}
                        </Typography>
                      </Grid>
                    </Hint>
                  )}
                </FlexibleWidthXYPlot>
              </Grid>

              <Divider orientation="horizontal" size={3} />

              <MaterialDivider light />

              <Divider orientation="horizontal" size={3} />

              <Typography variant="h4" color="textPrimary">
                Origem de acessos
              </Typography>

              <Divider orientation="horizontal" size={1} />

              <List>
                {siteMetrics?.site?.access?.byOrigin?.map((origin) => (
                  <ListItem className={classes.listItem} divider>
                    <Grid container alignItems="center" justify="space-between">
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        className={classes.upperCasedText}
                      >
                        {origin.name}
                      </Typography>

                      <Typography variant="overline">
                        {origin.count}{" "}
                        {getWordBasedOnCount("acesso", origin.count)}
                      </Typography>
                    </Grid>
                  </ListItem>
                ))}
              </List>

              {siteMetrics?.pages?.map((page) => (
                <>
                  <Divider orientation="horizontal" size={5} />

                  <Typography variant="h4" color="textPrimary">
                    {page.name}
                  </Typography>

                  <Divider orientation="horizontal" size={1} />

                  <List>
                    {page?.clicks?.length ? (
                      page?.clicks?.map((click) => (
                        <ListItem className={classes.listItem} divider>
                          <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                          >
                            <Typography
                              variant="h2"
                              color="textPrimary"
                              className={classes.upperCasedText}
                            >
                              {click.name}
                            </Typography>

                            <Typography variant="overline">
                              {click.count}{" "}
                              {getWordBasedOnCount("clique", click.count)}
                            </Typography>
                          </Grid>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem className={classes.listItem} divider>
                        <Typography variant="overline">
                          É necessário adicionar links nessa página para que os
                          dados de cliques apareçam aqui.
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </>
              ))}
            </Grid>
          </Loading>
        </Grid>
      }
    >
      <Fab size="medium">
        <MetricsIcon fontSize="large" color="primary" />
      </Fab>
    </EditDrawer>
  );
};

export default MetricsEditDrawer;
