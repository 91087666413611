import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	description: {
		maxWidth: 250
	},
	gridContainer: {
		padding: theme.spacing(4),
		backgroundColor: colors.palette.background,
		width: "100%",
		height: "100%"
	},
	image: {
		maxWidth: "100%",
		height: "auto"
	}
}));

export default useStyles;
