export const retrieveSiteBasePath = () => {
	const fullPath = window.location.pathname;

	/**
	 * Matches the following pattern: /sites/ID
	 * Ex:
	 * - /sites/100
	 */
	const siteBasePathRegex = /\/sites\/[0-9]*/g;

	const siteBasePath = fullPath.match(siteBasePathRegex)?.[0];

	return siteBasePath;
};

export const retrieveSiteId = () => {
	const siteBasePath = retrieveSiteBasePath();

	let siteId = null;

	if (siteBasePath) {
		const parsedId = siteBasePath.split("/").pop();

		if (parsedId) {
			siteId = +parsedId;
		}
	}

	return siteId;
};

export const getPageAssetUploadPath = (pageId: number) => {
	const pageAssetUploadPath = `/site/pages/${pageId}/asset`;

	return pageAssetUploadPath;
};
