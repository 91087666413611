import { makeStyles } from "@material-ui/core";

import { PAGE_CARD_ASPECT_RATIO } from "@/components/PageCard/styles";

import colors from "@/styles/colors";

const IMAGE_UPLOAD_WIDTH = 200;

const useStyles = makeStyles({
	tabs: {
		position: "relative",
		width: "100%",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.greyScale[5]
		}
	},
	tab: {
		minWidth: "auto !important",
		textTransform: "none",
		color: colors.greyScale[5],
		fontSize: 14,
		lineHeight: "24px",
		fontWeight: 500
	},
	tabIndicator: {
		backgroundColor: colors.greyScale[1]
	},
	selectedTab: {
		color: `${colors.greyScale[1]} !important`
	},
	imageUploadLabelContainer: {
		backgroundColor: colors.greyScale[11],
		width: IMAGE_UPLOAD_WIDTH,
		height: IMAGE_UPLOAD_WIDTH * PAGE_CARD_ASPECT_RATIO
	},
	imageUploadContainer: {
		alignItems: "flex-start"
	}
});

export default useStyles;
