import React from "react";

import {
	UploadFileResponse
} from "@/hooks/useFileUpload";

import { ImageUpload } from "@/components";

import { getSectionAssetUploadPath } from "@/@fp/utils/section";

type EditDrawerSectionPictureUploadProps = {
	onPictureUploaded: (data: UploadFileResponse<unknown>) => Promise<void>
	sectionId: number
	pictureUrl?: string
}

const EditDrawerSectionPictureUpload: React.FC<EditDrawerSectionPictureUploadProps> = (props) => {
	const {
		onPictureUploaded,
		sectionId,
		pictureUrl
	} = props;

	const requestPath = getSectionAssetUploadPath(sectionId);

	return (
		<ImageUpload
			onPictureUploaded={onPictureUploaded}
			requestPath={requestPath}
			pictureUrl={pictureUrl}
		/>
	);
};

export default EditDrawerSectionPictureUpload;
