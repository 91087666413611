import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { TemplatePage } from "@/protocols/page";

class TemplateService {
	async getTemplatePagesByCategoryId (
		templatePageCategoryId: number
	): Promise<TemplatePage[] | null> {
		try {
			const response = await ApiService.get("/site/template-pages", {
				params: {
					templatePageCategoryId
				}
			});

			const { templatePages } = response.data;

			return templatePages;
		} catch (error) {
			ErrorHandlerService.handle(error);
			return null;
		}
	}
}

export default new TemplateService();
