import StorageService from "@/services/Storage";

class AuthService {
	authTokenKey = "m!n111$it33_t0k3N"

	get authToken (): string | null {
		const authToken = StorageService.get<string>(this.authTokenKey);

		return authToken;
	}

	get isLoggedIn (): boolean {
		const authToken = this.authToken;

		return !!authToken;
	}

	login (authToken: string, callbackHref?: string): void {
		StorageService.set(this.authTokenKey, authToken);

		if (callbackHref) {
			window.location.replace(callbackHref);
		}
	}

	logout (callbackHref?: string): void {
		StorageService.delete(this.authTokenKey);

		if (callbackHref) {
			window.location.replace(callbackHref);
		}
	}
}

export default new AuthService();
