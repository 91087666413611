import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

const QRCodeEditDrawerSkeleton = () => (
	<Grid
		container
		alignItems="center"
		justify="center"
	>
		<Skeleton
			variant="rect"
			width="100%"
			height={300}
			animation="wave"
		/>
	</Grid>
);

export default QRCodeEditDrawerSkeleton;
