import { makeStyles } from "@material-ui/core";

import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: theme.spacing(1)
	},
	dialogContainer: {
		padding: theme.spacing(3)
	},
	loading: {
		color: colors.palette.error
	},
	cancelButton: {
		color: colors.greyScale[3]
	},
	confirmButton: {
		color: colors.palette.error
	},
	dialogDescription: {
		color: colors.greyScale[1]
	}
}));

export default useStyles;
