import React, { FormEvent, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
	Typography,
	TextField,
	Button,
	InputLabel,
	Grid,
	CircularProgress,
	Link as MaterialLink
} from "@material-ui/core";

import useStyles from "@/pages/Login/styles";
import useValidation from "@/hooks/useValidation";
import useDidMount from "@/hooks/useDidMount";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import AuthService from "@/services/Auth";

import { Divider } from "@/components";

import {
	formatEmail
} from "@/utils/mask";

type FormData = {
	email: string
	password: string
}

const Login: React.FC = () => {
	const [formData, setFormData] = useState({} as FormData);
	const [loading, setLoading] = useState(false);

	const classes = useStyles();
	const validation = useValidation();
	const history = useHistory();

	const handleChangeFormData = (key: keyof FormData, value: string) => {
		validation.clearValidation(key);

		setFormData(lastState => ({
			...lastState,
			[key]: value
		}));
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		setLoading(true);

		try {
			const result = await ApiService.post("/customer/login", formData);

			const authToken = result.data.authToken;

			AuthService.login(authToken);

			history.push("/bagy/setup");
		} catch (error) {
			validation.triggerValidation(error);
			ErrorHandlerService.handle(error);
		}

		setLoading(false);
	};

	useDidMount(() => {
		if (AuthService.isLoggedIn) {
			history.push("/site/list");
		}
	});

	return (
		<Grid
			component="form"
			className={classes.form}
			onSubmit={handleSubmit}
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Login
				</Typography>

				<Typography
					variant="body1"
				>
					Não tem conta?
					{" "}
					<MaterialLink
						component={Link}
						to="/bagy/signup"
					>
						Cadastre-se
					</MaterialLink>
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid>
				<InputLabel>E-mail</InputLabel>
				<TextField
					placeholder="Seu e-mail"
					onChange={({ target }) => handleChangeFormData("email", target.value)}
					value={formatEmail(formData.email)}
					helperText={validation.messages.email}
					error={Boolean(validation.messages.email)}
					variant="outlined"
					fullWidth
				/>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid>
				<InputLabel>Senha</InputLabel>
				<TextField
					placeholder="Sua senha"
					onChange={({ target }) => handleChangeFormData("password", target.value)}
					value={formData.password}
					helperText={validation.messages.password}
					error={Boolean(validation.messages.password)}
					type="password"
					variant="outlined"
					fullWidth
				/>
			</Grid>

			<Divider orientation="horizontal" size={1} />

			<Grid
				container
				justify="flex-end"
			>
				<Grid>
					<Typography
						variant="body1"
					>
						Esqueceu a senha?
						{" "}
						<MaterialLink
							component={Link}
							to="/bagy/recovery/request"
						>
							Clique aqui
						</MaterialLink>
					</Typography>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Button
				variant="contained"
				color="primary"
				type="submit"
				disableElevation
				fullWidth
				disabled={loading}
				endIcon={loading && (
					<CircularProgress
						size={20}
					/>
				)}
			>
				ENTRAR
			</Button>
		</Grid>
	);
};

export default Login;
