import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

import { Portlet, Divider } from "@/components";

const SiteListSkeleton = () => {
	const items = [];

	const totalItems = 2;

	// eslint-disable-next-line
	for (let i = 0; i < totalItems; i++) {
		items.push(
			<Grid
				key={i}
				item
				xs={12}
			>
				<Portlet
					elevation={1}
					style={{ height: 120 }}
				>
					<Skeleton variant="rect" width="50%" height={20} animation="wave" />

					<Divider orientation="horizontal" size={1} />

					<Skeleton variant="rect" width="30%" height={15} animation="wave" />
				</Portlet>
			</Grid>
		);
	}

	return (
		<Grid
			container
			spacing={2}
		>
			{items}
		</Grid>
	);
};

export default SiteListSkeleton;
