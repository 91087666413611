import { createMuiTheme } from "@material-ui/core";

import colors from "@/styles/colors";

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
	palette: {
		background: {
			default: colors.palette.background,
			paper: colors.greyScale[11]
		},
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: "#FFF"
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		},
		text: {
			primary: colors.greyScale[1]
		}
	},
	overrides: {
		MuiLink: {
			root: {
				color: colors.palette.link
			}
		},
		MuiFab: {
			root: {
				boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.08)",
				backgroundColor: colors.greyScale[11]
			}
		},
		MuiInputLabel: {
			root: {
				fontFamily: "IBM Plex Sans",
				fontStyle: "normal",
				fontWeight: "normal",
				fontSize: "14px",
				lineHeight: "24px",
				textTransform: "none",
				color: colors.greyScale[4]
			}
		},
		MuiFormHelperText: {
			root: {
				color: colors.greyScale[4]
			}
		},
		MuiListItemText: {
			secondary: {
				color: colors.greyScale[3]
			}
		},
		MuiListItemSecondaryAction: {
			root: {
				right: "0px"
			}
		},
		MuiDivider: {
			root: {
				height: "0.5px",
				backgroundColor: "#E3E3E3 !important"
			}
		},
		MuiListItem: {
			divider: {
				borderBottom: "0.5px solid #E3E3E3"
			}
		}
	},
	typography: {
		fontFamily: "IBM Plex Sans",
		h1: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "15px",
			lineHeight: "20px",
			color: colors.greyScale[2]
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "19px",
			letterSpacing: "0.07em",
			color: colors.greyScale[3]
		},
		h3: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "26px",
			lineHeight: "48px",
			color: colors.greyScale[1]
		},
		h4: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "16px",
			lineHeight: "24px",
			color: colors.greyScale[4]
		},
		h5: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "bold",
			fontSize: "12px",
			lineHeight: "16px"
		},
		body1: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "16px",
			lineHeight: "24px",
			color: colors.greyScale[4]
		},
		overline: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "14px",
			lineHeight: "24px",
			textTransform: "none",
			color: colors.greyScale[4]
		},
		caption: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "12px",
			lineHeight: "16px",
			color: colors.greyScale[3]
		},
		button: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "16px",
			lineHeight: "24px"
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		0: "0px 0px 0px rgba(0, 0, 0, 0)",
		1: "0px 2px 4px rgba(33, 36, 41, 0.08)",
		2: "0px 4px 16px rgba(33, 36, 41, 0.24)"
	// eslint-disable-next-line
	}) as any
});

export default theme;
