import React, { useState } from "react";
import { Switch } from "react-router-dom";

import OrganizationService from "@/services/Organization";

import useDidMount from "@/hooks/useDidMount";
import { useGlobalStateStore } from "@/store/GlobalState";

import {
	Loading,
	PageContainer,
	OrganizationHeader
} from "@/components";

import { PublicCPRoutes } from "@/@organizations/Bagy/routes/public";

export const BagyPublicCPRoutes = () => {
	const [loading, setLoading] = useState(true);

	const globalStateStore = useGlobalStateStore();

	const getData = async () => {
		const data = await OrganizationService.getByType("bagy");

		if (data) {
			globalStateStore.setOrganizationData(data);
		}

		setLoading(false);
	};

	useDidMount(() => {
		getData();
	});

	return (
		<Loading
			loading={loading}
		>
			<PageContainer>
				<OrganizationHeader />

				<Switch>
					{PublicCPRoutes}
				</Switch>
			</PageContainer>
		</Loading>
	);
};
