import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles({
	fieldEditExampleText: {
		fontWeight: "normal",
		border: `1px dashed ${colors.greyScale[3]}`
	},
	iconButtonGroup: {
		flex: 1
	}
});

export default useStyles;
