import React from "react";
import { useParams } from "react-router-dom";

import useDidMount from "@/hooks/useDidMount";

import { Loading } from "@/components";

import AuthService from "@/services/Auth";
import BumeService from "@/services/Bume";
import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

const Login = () => {
	const { loginToken, siteId } = useParams<{ loginToken: string, siteId: string }>();

	const handleLogin = async () => {
		BumeService.login();

		let authToken = null;

		try {
			const response = await ApiService.post(`/customer/login/${loginToken}`);

			authToken = response?.data?.authToken;
		} catch (error) {
			ErrorHandlerService.handle(error);
		}

		AuthService.login(authToken, `/sites/${siteId}/pages`);
	};

	useDidMount(() => {
		handleLogin();
	});

	return (
		<Loading loading />
	);
};

export default Login;
