import React, { useEffect } from "react";
import {
	Dialog as MaterialDialog,
	IconButton
} from "@material-ui/core";
import {
	CloseOutlined as CloseIcon
} from "@material-ui/icons";

import { handleBackButton } from "@/utils/backButton";
import { isMobile } from "@/utils/checkDevice";

import useStyles from "@/@fp/components/Dialog/styles";

type DialogProps = {
	opened: boolean
	onClose: () => void
	disablePadding?: boolean
	iconButtonStyle?: React.CSSProperties
	dialogPaperStyle?: React.CSSProperties
}

const Dialog: React.FC<DialogProps> = (props) => {
	const {
		children,
		opened,
		onClose,
		dialogPaperStyle,
		iconButtonStyle
	} = props;

	const classes = useStyles();

	useEffect(() => {
		if (isMobile && opened) {
			handleBackButton(
				() => onClose()
			);
		}
	// eslint-disable-next-line
	}, [opened])

	return (
		<MaterialDialog
			open={opened}
			onClose={onClose}
			fullWidth
			PaperProps={{
				className: classes.paper,
				style: dialogPaperStyle
			}}
		>
			<IconButton
				onClick={onClose}
				className={classes.closeIcon}
				style={iconButtonStyle}
			>
				<CloseIcon />
			</IconButton>

			{children}
		</MaterialDialog>
	);
};

export default Dialog;
