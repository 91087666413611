import { makeStyles } from "@material-ui/core";

import colors from "@/styles/colors";

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(3)
	},
	link: {
		fontWeight: "bold",
		display: "block",
		flex: 1,
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	arrowIndicator: {
		transform: "rotate(90deg)",
		color: colors.greyScale[4]
	},
	copyButton: {
		color: colors.greyScale[3]
	},
	headerContent: {
		flex: 1,
		transform: "translateY(18px)"
	},
	linkContainer: {
		flex: 1
	},
	defaultPrivateComponentContainer: {
		height: "100%"
	}
}));

export default useStyles;
