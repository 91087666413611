import React, { createContext, useContext, useState } from "react";

import { SiteData } from "@/protocols/site";

import StorageService from "@/services/Storage";

type Site = SiteData["site"];

export type Customer = SiteData["customer"];

type Organization = SiteData["organization"];

export interface SignupDataAmplitudeProps {
  customerId?: number;
  nome: string;
  email: string;
  telefone: string;
  "segmento da loja": string;
}

export interface GlobalStateContextData {
  site: Site;
  customer: Customer;
  organization: Organization;
  signupDataAmplitude: SignupDataAmplitudeProps | undefined;
  setSiteData: (data: Partial<Site>) => void;
  setCustomerData: (data: Partial<Customer>) => void;
  setOrganizationData: (data: Partial<Organization>) => void;
  setSignupDataAmplitude: (data: SignupDataAmplitudeProps | undefined) => void;
}

const GlobalStateStore = createContext<GlobalStateContextData>(
  {} as GlobalStateContextData
);

export const useGlobalStateStore = () => useContext(GlobalStateStore);

const GlobalStateProvider: React.FC = (props) => {
  const { children } = props;

  const [signupDataAmplitude, setSignupDataAmplitude] = useState<
    SignupDataAmplitudeProps | undefined
  >((): SignupDataAmplitudeProps | undefined => {
    const data = StorageService.get<SignupDataAmplitudeProps>(
      "signupDataAmplitude"
    );
    if (data) {
      return data;
    }
  });
  const [site, setSite] = useState<Site>({} as Site);
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );

  const setSignupDataAmplitudeWrapper = (
    data: SignupDataAmplitudeProps | undefined
  ) => {
    StorageService.set("signupDataAmplitude", data);
    setSignupDataAmplitude(data);
  };

  const setSiteData = (data: Partial<Site>) => {
    setSite((lastState) => ({
      ...lastState,
      ...data,
    }));
  };

  const setOrganizationData = (data: Partial<Organization>) => {
    setOrganization((lastState) => ({
      ...lastState,
      ...data,
    }));
  };

  const setCustomerData = (data: Partial<Customer>) => {
    setCustomer((lastState) => ({
      ...lastState,
      ...data,
    }));
  };

  return (
    <GlobalStateStore.Provider
      value={{
        site,
        customer,
        organization,
        signupDataAmplitude,
        setSiteData,
        setCustomerData,
        setOrganizationData,
        setSignupDataAmplitude: setSignupDataAmplitudeWrapper,
      }}
    >
      {children}
    </GlobalStateStore.Provider>
  );
};

export default GlobalStateProvider;
