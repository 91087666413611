import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { Section } from "@/@fp/protocols/Section";
import { SiteSettings } from "@/@fp/protocols/Site";
import { TemplatePageData, PageType } from "@/@fp/protocols/Page";

type PageData = {
	active: boolean
	type: PageType
	siteSettings: SiteSettings
	templateData: TemplatePageData
}

class PageService {
	async updateSectionOnDatabase (
		sectionId: number,
		data: Pick<Section, "content" | "properties">
	): Promise<boolean> {
		try {
			await ApiService.put(`/site/page-sections/${sectionId}`, {
				properties: data.properties,
				content: data.content
			});

			return true;
		} catch (error) {
			ErrorHandlerService.handle(error);

			return false;
		}
	}

	async updateSectionOnDatabaseBySectionCategoryAndSiteId (
		sectionCategory: string,
		siteId: number,
		data: Pick<Section, "content" | "properties">
	): Promise<boolean> {
		try {
			await ApiService.put(`/site/${siteId}/section-category/${sectionCategory}/page-sections`, {
				properties: data.properties,
				content: data.content
			});

			return true;
		} catch (error) {
			ErrorHandlerService.handle(error);

			return false;
		}
	}

	async getPageData (pageId: number): Promise<PageData | null> {
		try {
			const response = await ApiService.get("/site/pages/single-data", {
				params: {
					pageId
				}
			});

			const pageData = response.data;

			return pageData;
		} catch (error) {
			ErrorHandlerService.handle(error);
			return null;
		}
	}
}

export default new PageService();
