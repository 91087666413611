import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  lowerCaseButton: {
    fontSize: "14px",
    textTransform: "none",
  },
  crownImage: {
    paddingRight: "5px",
  },
}));

export default useStyles;
