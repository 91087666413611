import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";

import useStyles from "@/@organizations/Bagy/pages/Setup/styles";
import useOrganization from "@/hooks/useOrganization";

import { Divider, SetupListItem } from "@/components";

import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";

const Setup: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const organization = useOrganization();

  const handleGoToSiteList = () => {
    amplitude.getInstance().logEvent("Painel - Acessou o bagy.bio");
    history.push("/site/list");
    const analytics = getAnalytics();
    logEvent(analytics, "Painel - Acessou o bagy.bio");
  };

  const handleOpenLandingPage = () => {
    window.location.href =
      "https://site.bagy.com.br/?utm_source=painel_bagybio&utm_medium=bagybio&utm_campaign=banner_bagybio";
  };

  return (
    <Grid className={classes.container}>
      <Grid>
        <Typography variant="h3">Qual o seu objetivo?</Typography>

        <Typography variant="body1">
          Selecione a opção que representa seu perfil
        </Typography>
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <SetupListItem
        onClick={handleGoToSiteList}
        title="BagyBio"
        description="Muito mais do que um link na bio. Com BagyBio você adiciona suas redes sociais, links, vídeos, whatsapp e muito mais, tudo isso dentro de apenas um único lugar!"
        buttonText="Acessar"
        logoSrc={organization.data.headerLogo}
      />

      <Divider orientation="horizontal" size={2} />

      <SetupListItem
        onClick={handleOpenLandingPage}
        title="Bagy"
        description="Bagy é a forma mais fácil de vender com a sua loja na internet e no Instagram. Em menos de 15 minutos você começa a vender online com um site de vendas conectado com as suas redes sociais sem precisar entender nada de tecnologia."
        buttonText="Teste grátis agora"
        logoSrc="https://mini-site-bagy-prod-data.s3.us-west-2.amazonaws.com/brand/bagy_brand_logo.svg"
      />
    </Grid>
  );
};

export default Setup;
