import React, { useState } from "react";
import {
	Grid,
	Fab,
	Typography
} from "@material-ui/core";
import {
	ShareOutlined as ShareIcon
} from "@material-ui/icons";

import {
	Loading,
	EditDrawer
} from "@/components";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import QRCodeService from "@/services/QRCode";
import ImageService from "@/services/Image";

import QRCodeEditDrawerSkeleton from "@/skeletons/QRCodeEditDrawer";

import useDidMount from "@/hooks/useDidMount";
import useStyles from "@/pages/PageList/PageEdit/QRCodeEditDrawer/styles";
import useChildrenImage from "@/hooks/useChildrenImage";

type QRCode = {
	url: string
	QRCodeImageUrl: string
}

type QRCodeEditDrawerProps = {
	siteId: number
	sitePageId: number
}

const QRCodeEditDrawer: React.FC<QRCodeEditDrawerProps> = (props) => {
	const { siteId, sitePageId } = props;

	const classes = useStyles();
	const childrenImage = useChildrenImage();

	const [QRCode, setQRCode] = useState<QRCode>({} as QRCode);
	const [loadingQRCode, setLoadingQRCode] = useState(true);

	const getQRCode = async () => {
		try {
			const response = await ApiService.get("/site/qr-code/page", {
				params: {
					siteId,
					sitePageId
				}
			});

			const QRCodeUrl = response.data.url as string;

			const qrCodeImageUrl = await QRCodeService.generate(QRCodeUrl, {
				width: 800,
				quality: 1,
				margin: 0
			});

			setQRCode(lastState => ({
				...lastState,
				url: QRCodeUrl,
				QRCodeImageUrl: qrCodeImageUrl || "",
				sharableImageUrl: ""
			}));
		} catch (error) {
			ErrorHandlerService.handle(error);
		}

		setLoadingQRCode(false);
	};

	const getQRCodeFileName = () => {
		const params = [];

		params.push("qr-code");

		if (siteId) {
			params.push(`-site-${siteId}`);
		}

		if (sitePageId) {
			params.push(`-pagina-${sitePageId}`);
		}

		params.push(".png");

		const fileName = params.join("");

		return fileName;
	};

	const handleDownloadQRCode = async () => {
		const QRCodeFileName = getQRCodeFileName();

		const sharableImageUrl = await childrenImage.getChildrenImage();

		if (sharableImageUrl) {
			ImageService.downloadImageByUrl(QRCodeFileName, sharableImageUrl || "");
		}

		/**
		 * Prevent showing save success image
		 */
		return false;
	};

	const getData = async () => {
		await getQRCode();
	};

	useDidMount(() => {
		getData();
	});

	return (
		<EditDrawer
			title="QR Code"
			description="Faça o download do QR, imprima como adesivo e cole no seu estabelecimento."
			saveButtonText="DOWNLOAD"
			onSave={handleDownloadQRCode}
			pureChildren
			formComponent={(
				<Loading loading={loadingQRCode} customLoadingElement={<QRCodeEditDrawerSkeleton />}>
					{childrenImage.wrapChildren((
						<Grid container>
							<Grid
								container
								alignItems="center"
								justify="center"
								direction="column"
								className={classes.QRCodeContainer}
							>
								<img
									src={QRCode.QRCodeImageUrl}
									alt="QR Code da sua página"
									className={classes.QRCodeImage}
								/>

								<Typography
									variant="overline"
									color="textPrimary"
									className={classes.QRCodeText}
								>
									{QRCode.url}
								</Typography>
							</Grid>
						</Grid>
					), {
						containerWidth: 500
					})}
				</Loading>
			)}
		>
			<Fab
				size="medium"
			>
				<ShareIcon
					fontSize="large"
					color="primary"
				/>
			</Fab>
		</EditDrawer>
	);
};

export default QRCodeEditDrawer;
