import React from "react";
import {
	Typography,
	TextField
} from "@material-ui/core";

import useOrganization from "@/hooks/useOrganization";
import useStyles from "@/components/SlugInput/styles";

export type SlugInputProps = {
	validationMessage?: string
	slugSuggestion?: string
	value?: string
	onChange: (slug: string) => void
	onUseSlugSuggestion: (slug: string) => void
	hideHelperTextPlaceholder?: boolean
}

const SlugInput: React.FC<SlugInputProps> = (props) => {
	const {
		onChange,
		onUseSlugSuggestion,
		slugSuggestion,
		validationMessage,
		value,
		hideHelperTextPlaceholder
	} = props;

	const organization = useOrganization();
	const classes = useStyles();

	const handleChangeSlug = (slug: string) => {
		onChange(slug);
	};

	const handleUseSlugSuggestion = () => {
		onUseSlugSuggestion(slugSuggestion as string);
	};

	return (
		<TextField
			InputProps={{
				startAdornment: (
					<Typography>
						<b>{organization.data.appHost}/</b>
					</Typography>
				)
			}}
			placeholder="seunome"
			variant="outlined"
			value={value}
			onChange={({ target }) => handleChangeSlug(target.value)}
			error={!!validationMessage}
			helperText={(
				<>
					{validationMessage ? (
						<>
							{validationMessage}
							{" "}
							{slugSuggestion && (
								<>
									Sugestão:
									{" "}
									<b
										className={classes.suggestionText}
										onClick={handleUseSlugSuggestion}
									>
										{slugSuggestion}
									</b>
								</>
							)}
						</>
					) : (
						<>
							{!hideHelperTextPlaceholder && "Exemplo: seunome"}
						</>
					)}
				</>
			)}
			fullWidth
		/>
	);
};

export default SlugInput;
