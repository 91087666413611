import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridButton: {
    marginTop: theme.spacing(4),
    justifyContent: "left",
  },
}));

export default useStyles;
