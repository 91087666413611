import React, { useState } from "react";
import {
	Dialog,
	Grid,
	Typography,
	Button,
	ThemeProvider,
	CircularProgress
} from "@material-ui/core";

import theme from "@/styles/theme";

import { renderComponent } from "@/utils/node";
import { isMobile } from "@/utils/checkDevice";
import { handleBackButton } from "@/utils/backButton";

import useDidMount from "@/hooks/useDidMount";

import { Divider } from "@/components";

import useStyles from "@/components/PopConfirm/styles";

type PopConfirmProps = {
	description: string
	confirmButtonText: string
	onConfirm: () => Promise<void>
}

type PopConfirmType = {
	open: (props: PopConfirmProps) => void
}

const PopConfirm: PopConfirmType & React.FC<PopConfirmProps> = (props) => {
	const { description, onConfirm, confirmButtonText } = props;

	const classes = useStyles();

	const [opened, setOpened] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setOpened(false);
	};

	const handleOk = async () => {
		setLoading(true);

		await onConfirm();

		setLoading(false);

		handleClose();
	};

	useDidMount(() => {
		if (isMobile) {
			handleBackButton(
				() => handleClose()
			);
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
			>
				<Grid
					container
					className={classes.dialogContainer}
				>
					<Typography
						variant="h4"
						className={classes.dialogDescription}
					>
						{description}
					</Typography>

					<Divider orientation="horizontal" size={4} />

					<Grid
						container
						justify="flex-end"
					>
						<Button
							onClick={handleClose}
							variant="text"
							className={classes.cancelButton}
						>
							Cancelar
						</Button>
						<Button
							endIcon={loading && <CircularProgress size={20} className={classes.loading} />}
							onClick={handleOk}
							variant="text"
							className={classes.confirmButton}
						>
							{confirmButtonText}
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	);
};

PopConfirm.open = (props: PopConfirmProps) => {
	renderComponent(
		"pop-confirm",
		<PopConfirm
			{...props}
		/>
	);
};

export default PopConfirm;
