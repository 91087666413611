import React, { useState } from "react";
import {
  Grid,
  Typography,
  List,
  InputLabel,
  TextField,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ArrowForwardIos as ArrowRightIcon } from "@material-ui/icons";

import { Portlet, EditDrawer, Loading } from "@/components";

import useCustomStyles from "@/styles/custom";
import useValidation from "@/hooks/useValidation";
import useDidMount from "@/hooks/useDidMount";
import { formatEmail, formatPhoneNumber } from "@/utils/mask";

import ApiService from "@/services/Api";

import AccountSkeleton from "@/skeletons/Account";
import PurchaseBagyButton from "@/components/PurchaseBagyButton";
import useStyles from "./style";

type AccountData = {
  name: string;
  email: string;
  phone_number: string;
};

const Account = () => {
  const [accountData, setAccountData] = useState<AccountData>(
    {} as AccountData
  );

  const [loading, setLoading] = useState(true);

  const customClasses = useCustomStyles();
  const validation = useValidation();

  const loadAccountData = async () => {
    const response = await ApiService.get("/customer/account");

    setAccountData(response.data);

    setLoading(false);
  };

  const handleSaveAccountItemData = async (key: keyof AccountData) => {
    try {
      await ApiService.put("/customer/account", {
        [key]: accountData[key],
      });

      return true;
    } catch (error) {
      validation.triggerValidation(error);

      return false;
    }
  };

  const handleChangeAccountData = (key: keyof AccountData, value: unknown) => {
    setAccountData((lastState) => ({
      ...lastState,
      [key]: value,
    }));

    validation.clearValidation(key);
  };
  const classes = useStyles();

  useDidMount(() => {
    loadAccountData();
  });

  return (
    <Loading customLoadingElement={<AccountSkeleton />} loading={loading}>
      <Grid container>
        <Portlet elevation={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="button">DADOS DA CONTA</Typography>
            </Grid>

            <Grid item xs={12}>
              <List disablePadding>
                <EditDrawer
                  title="Alterar nome"
                  description="Escolha um novo nome para a sua conta"
                  onSave={async () => await handleSaveAccountItemData("name")}
                  formComponent={
                    <Portlet elevation={1}>
                      <InputLabel>Nome</InputLabel>
                      <TextField
                        placeholder="Nome da conta"
                        variant="outlined"
                        value={accountData.name}
                        onChange={({ target }) =>
                          handleChangeAccountData("name", target.value)
                        }
                        fullWidth
                      />
                    </Portlet>
                  }
                  pureChildren
                >
                  <ListItem button disableGutters>
                    <ListItemText primary="Nome" secondary={accountData.name} />

                    <ArrowRightIcon
                      className={customClasses.listItemIcon}
                      fontSize="small"
                    />
                  </ListItem>
                </EditDrawer>

                <EditDrawer
                  title="Alterar e-mail"
                  description="Escolha um novo e-mail para a sua conta"
                  onSave={async () => await handleSaveAccountItemData("email")}
                  formComponent={
                    <Portlet elevation={1}>
                      <InputLabel>E-mail</InputLabel>
                      <TextField
                        placeholder="E-mail da conta"
                        variant="outlined"
                        value={formatEmail(accountData.email)}
                        onChange={({ target }) =>
                          handleChangeAccountData("email", target.value)
                        }
                        helperText={validation.messages.email}
                        error={Boolean(validation.messages.email)}
                        fullWidth
                      />
                    </Portlet>
                  }
                  pureChildren
                >
                  <ListItem button disableGutters>
                    <ListItemText
                      primary="E-mail"
                      secondary={accountData.email}
                    />

                    <ArrowRightIcon
                      className={customClasses.listItemIcon}
                      fontSize="small"
                    />
                  </ListItem>
                </EditDrawer>

                <EditDrawer
                  title="Alterar número de telefone"
                  description="Escolha um novo número de telefone para a sua conta"
                  onSave={async () =>
                    await handleSaveAccountItemData("phone_number")
                  }
                  formComponent={
                    <Portlet elevation={1}>
                      <InputLabel>Número de telefone</InputLabel>
                      <TextField
                        placeholder="Número de telefone da conta"
                        variant="outlined"
                        value={formatPhoneNumber(accountData.phone_number)}
                        onChange={({ target }) =>
                          handleChangeAccountData("phone_number", target.value)
                        }
                        fullWidth
                      />
                    </Portlet>
                  }
                  pureChildren
                >
                  <ListItem button disableGutters>
                    <ListItemText
                      primary="Número de telefone"
                      secondary={accountData.phone_number}
                    />

                    <ArrowRightIcon
                      className={customClasses.listItemIcon}
                      fontSize="small"
                    />
                  </ListItem>
                </EditDrawer>

                <ListItem
                  component="a"
                  href="/recovery/generate"
                  target="_self"
                  button
                  disableGutters
                >
                  <ListItemText primary="Redefinir senha" />

                  <ArrowRightIcon
                    className={customClasses.listItemIcon}
                    fontSize="small"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Portlet>
        <Grid container className={classes.gridButton}>
          <PurchaseBagyButton
            url={
              "https://site.bagy.com.br/?utm_source=painel_bagybio&utm_medium=bagybio&utm_campaign=menu_bagybio"
            }
            type={"link"}
          />
        </Grid>
      </Grid>
    </Loading>
  );
};

export default Account;
