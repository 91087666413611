import React, { useRef } from "react";
import TinySlider, { TinySliderInfo, SliderRef, CarouselRef } from "tiny-slider-react";
import { Grid } from "@material-ui/core";

import useStyles from "@/pages/FP/StoryCarousel/styles";

import TabBar, { TabBarHandler } from "@/pages/FP/StoryCarousel/TabBar";

type StoryCarouselProps = {
	onSlideChange?: (index: number) => void
	initialSlideIndex?: number
}

const StoryCarousel: React.FC<StoryCarouselProps> = (props) => {
	const { children, onSlideChange, initialSlideIndex } = props;

	const tabBarRef = useRef<TabBarHandler>(null);
	const sliderContainerRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<SliderRef | null>(null);

	const slideCount = React.Children.count(children);

	const classes = useStyles();

	const onSlideIndexChanged = (info: TinySliderInfo) => {
		tabBarRef.current?.handleChangeTabIndex(info.index);

		if (onSlideChange) {
			onSlideChange(info.index);
		}
	};

	const onTabIndexChanged = (nextIndex: number) => {
		sliderRef.current?.goTo(nextIndex);

		if (onSlideChange) {
			onSlideChange(nextIndex);
		}
	};

	const handleCarouselRef = (carouselRef: CarouselRef) => {
		sliderRef.current = carouselRef?.slider;
	};

	// const onLongTouch = () => {
	// 	const TOUCH_DURATION = 500

	// 	let timer: NodeJS.Timeout

	// 	const handleLongPress = () => {
	// 		slider.pause()

	// 		progressBarRef.current?.handleToggleHidden(true)
	// 		progressBarRef.current?.pause()
	// 	}

	// 	const handlePressStart = (event: TouchEvent | MouseEvent) => {
	// 		const pressedElement = event.target as HTMLElement

	// 		let pressedElementTagName = pressedElement.tagName || ""
	// 		let pressedElementClassName = ""

	// 		if (typeof pressedElement?.className !== "object") {
	// 			pressedElementClassName = pressedElement?.className
	// 		}

	// 		pressedElementClassName = pressedElementClassName.toLowerCase()
	// 		pressedElementTagName = pressedElementTagName.toLowerCase()

	// 		const isLink = ["a", "svg", "path"].includes(pressedElementTagName)
	// 		const isButton = pressedElementClassName.includes("button")

	// 		document.oncontextmenu = event => {
	// 			if (isMobile && !isLink && !isButton) {
	// 				event.preventDefault()
	// 			}
	// 		}

	// 		timer = setTimeout(handleLongPress, TOUCH_DURATION)
	// 	}

	// 	const handlePressEnd = () => {
	// 		if (timer) {
	// 			clearTimeout(timer)
	// 		}

	// 		slider.play()

	// 		progressBarRef.current?.handleToggleHidden(false)
	// 		progressBarRef.current?.start()
	// 	}

	// 	if (isMobile) {
	// 		sliderContainerRef.current?.addEventListener("touchstart", handlePressStart)
	// 		sliderContainerRef.current?.addEventListener("touchend", handlePressEnd)
	// 	} else {
	// 		sliderContainerRef.current?.addEventListener("mousedown", handlePressStart)
	// 		window.addEventListener("mouseup", handlePressEnd)
	// 	}
	// }

	return (
		<Grid
			container
			className={`${classes.tinySliderContainer} ${slideCount > 1 && classes.slideHalfScreenAnimation}`}
			ref={sliderContainerRef}
		>
			<TabBar
				onIndexChange={onTabIndexChanged}
				initialTabIndex={initialSlideIndex || 0}
				tabBarCount={slideCount > 1 ? slideCount : 0}
				ref={tabBarRef}
			/>

			<TinySlider
				settings={{
					nav: false,
					mouseDrag: true,
					controls: false,
					loop: true,
					rewind: true,
					autoHeight: true,
					swipeAngle: 45,
					arrowKeys: false,
					startIndex: initialSlideIndex
				}}
				ref={handleCarouselRef}
				onIndexChanged={onSlideIndexChanged}
			>
				{React.Children.map(children, child => (
					<Grid
						container
						className={classes.tinySliderContent}
					>
						{child}
					</Grid>
				))}
			</TinySlider>
		</Grid>
	);
};

export default StoryCarousel;
