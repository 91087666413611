import React from "react";

import useStyles from "@/@fp/components/FancyBox/styles";

/**
 * Work around to load FancyBox on React.js
 */
// eslint-disable-next-line
const Window = window as any
Window.jQuery = require("jquery");
require("@fancyapps/fancybox");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");

type FancyBoxProps = {
	caption: string
	src: string
	id?: string
	options: {
		slug?: string
		enableHashChange?: boolean
	}
}

const FancyBox: React.FC<FancyBoxProps> = (props) => {
	const { children, src, caption, id, options } = props;

	const classes = useStyles();

	return (
		<a
			data-fancybox={options?.slug}
			data-hash={options?.enableHashChange}
			href={src}
			data-caption={caption}
			className={classes.link}
			id={id}
		>
			{children}
		</a>
	);
};

export default FancyBox;
