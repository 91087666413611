import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  organizationBrand: {
    maxWidth: "100%",
    maxHeight: theme.spacing(5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    fontFamily: "Montserrat",
  },
}));

export default useStyles;
