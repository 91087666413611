import React from "react";
import { Grid } from "@material-ui/core";

import useStyles from "@/components/OrganizationAdvertisingBanner/styles";

type OrganizationAdvertisingBannerProps = {
	bannerImageUrl: string
	bannerRedirectUrl: string
	style?: React.CSSProperties
}

const OrganizationAdvertisingBanner: React.FC<OrganizationAdvertisingBannerProps> = (props) => {
	const { bannerImageUrl, bannerRedirectUrl, style } = props;

	const classes = useStyles();

	return (
		<Grid
			component="a"
			container
			className={classes.bannerContainer}
			style={{
				...(style || {}),
				backgroundImage: `url(${bannerImageUrl})`
			}}
			href={bannerRedirectUrl}
			target="_blank"
		/>
	);
};

export default OrganizationAdvertisingBanner;
