import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
  },
  cardGridItem: {
    position: "relative",
  },
  buttonContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
  },
  addPageButtonText: {
    fontWeight: 600,
  },
  editPageIcon: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  pageTextContainer: {
    minHeight: 44,
  },
  inactivePageImage: {
    boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.15)",
  },
  iconButtonGroup: {
    flex: 1,
  },
}));

export default useStyles;
