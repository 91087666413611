import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	selectedButton: {
		border: "none"
	},
	selectedIcon: {
		width: 22,
		height: 22,
		right: -5,
		bottom: -5,
		minHeight: 22,
		"& svg": {
			height: 18,
			width: 18
		}
	},
	buttonContainer: {
		flexBasis: "0%"
		// [theme.breakpoints.up("sm")]: {
		// 	flexBasis: "20%"
		// }
	},
	buttonGroupContainer: {
		flexWrap: "nowrap",
		whiteSpace: "nowrap",
		overflowX: "scroll",
		scrollbarWidth: "none",
		flex: "1 1 auto",
		position: "relative",
		"&::-webkit-scrollbar": {
			width: "0px",
			height: "0px"
		},
		[theme.breakpoints.up("sm")]: {
			flexWrap: "wrap"
		}
	}
}));

export default useStyles;
