import React, { FormEvent, useState } from "react";
import {
	Typography,
	TextField,
	Button,
	InputLabel,
	Grid,
	CircularProgress
} from "@material-ui/core";

import useStyles from "@/pages/Login/styles";
import useValidation from "@/hooks/useValidation";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { Divider, PageContainer, Notification } from "@/components";

type FormData = {
	email: string
}

const Generate: React.FC = () => {
	const [formData, setFormData] = useState({} as FormData);
	const [loading, setLoading] = useState(false);

	const classes = useStyles();
	const validation = useValidation();

	const handleChangeFormData = (key: keyof FormData, value: string) => {
		validation.clearValidation(key);

		setFormData(lastState => ({
			...lastState,
			[key]: value
		}));
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		setLoading(true);

		try {
			await ApiService.post("/auth/password/recovery/request", formData);

			Notification.success({
				message: "Você receberá um e-mail com as instruções para redefinição de senha."
			});
		} catch (error) {
			validation.triggerValidation(error);
			ErrorHandlerService.handle(error);
		}

		setLoading(false);
	};

	return (
		<PageContainer>
			<Grid
				component="form"
				className={classes.form}
				onSubmit={handleSubmit}
			>
				<Grid>
					<Typography
						variant="h3"
					>
						Redefinir senha
					</Typography>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid>
					<InputLabel>E-mail</InputLabel>
					<TextField
						placeholder="Seu e-mail"
						onChange={({ target }) => handleChangeFormData("email", target.value)}
						value={formData.email}
						helperText={validation.messages.email || "Preencha o seu e-mail e iremos enviar um link para redefinição de senha."}
						error={Boolean(validation.messages.email)}
						variant="outlined"
						fullWidth
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Button
					variant="contained"
					color="primary"
					type="submit"
					disableElevation
					fullWidth
					disabled={loading}
					endIcon={loading && (
						<CircularProgress
							size={20}
						/>
					)}
				>
					REDEFINIR SENHA
				</Button>
			</Grid>
		</PageContainer>
	);
};

export default Generate;
