import React from "react";
import {
	Grid,
	Typography
} from "@material-ui/core";

import noPictureImg from "@/assets/placeholders/no_product_cover.png";

import useStyles from "@/@fp/components/EditDrawerSectionCardItem/styles";

type EditDrawerSectionCardItemProps = {
	pictureUrl?: string
	title?: string
	description?: string
	caption?: string
	titleStyles?: React.CSSProperties
	descriptionStyles?: React.CSSProperties
}

const EditDrawerSectionCardItem: React.FC<EditDrawerSectionCardItemProps> = (props) => {
	const {
		pictureUrl,
		title,
		titleStyles,
		description,
		descriptionStyles,
		caption
	} = props;

	const classes = useStyles();

	return (
		<Grid container>
			<Grid
				container
				className={classes.cardItemCoverContainer}
			>
				<Grid
					className={classes.cardItemCover}
					justify="center"
					alignItems="center"
					style={{
						backgroundImage: `url(${pictureUrl || noPictureImg})`
					}}
				/>
			</Grid>

			<Grid
				container
				direction="column"
				alignItems="flex-start"
				className={classes.cardItemInfoContainer}
			>
				{title && (
					<Typography
						variant="h2"
						className={`${classes.cardInfoTitle} ${classes.limitedTextSizeStyles}`}
						style={titleStyles || {}}
					>
						{title}
					</Typography>
				)}

				{description && (
					<Typography
						align="left"
						variant="overline"
						className={`${classes.cardInfoDescription} ${classes.limitedTextSizeStyles}`}
						style={descriptionStyles || {}}
					>
						{description}
					</Typography>
				)}

				{caption && (
					<Typography
						variant="overline"
						className={`${classes.cardInfoCaption} ${classes.limitedTextSizeStyles}`}
					>
						{caption}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default EditDrawerSectionCardItem;
