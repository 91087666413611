export const isLastItem = (index: number, totalItems?: number) => {
	return (index + 1) === totalItems;
};

export const isFirstItem = (index: number) => {
	return (index === 0);
};

// eslint-disable-next-line
export function moveItem<ArrayType extends Array<any>> (
	array: ArrayType | null | undefined,
	fromIndex: number,
	toIndex: number
): ArrayType | null | undefined {
	const isValidMovement = fromIndex >= 0 && toIndex >= 0;

	if (!array || !isValidMovement) {
		return array;
	}

	const fromItem = array[fromIndex];

	const toItem = array[toIndex];

	const changedArray = [...array];

	changedArray.splice(toIndex, 1, fromItem);
	changedArray.splice(fromIndex, 1, toItem);

	if (changedArray.length !== array.length) {
		return array;
	}

	return changedArray as ArrayType;
}

export const moveItemElement = async (
	fromElementId: string,
	toElementId: string
): Promise<void> => {
	const ANIMATION_IN_MILLISECONDS = 300;

	const fromElement = document.getElementById(fromElementId) as HTMLElement;
	const toElement = document.getElementById(toElementId) as HTMLElement;

	if (!fromElement || !toElement) {
		return;
	}

	const {
		top: fromElementTopPosition,
		left: fromElementLeftPosition
	} = fromElement.getBoundingClientRect();

	const {
		top: toElementTopPosition,
		left: toElementLeftPosition
	} = toElement.getBoundingClientRect();

	fromElement.style.transitionDuration = `${ANIMATION_IN_MILLISECONDS}ms`;
	toElement.style.transitionDuration = `${ANIMATION_IN_MILLISECONDS}ms`;

	let fromElementOffsetHeight = "0px";
	let toElementOffsetHeight = "0px";
	let fromElementOffsetWidth = "0px";
	let toElementOffsetWidth = "0px";

	/**
	 * In case the new position changed vertically
	 */
	if (fromElementTopPosition !== toElementTopPosition) {
		fromElementOffsetHeight = `${fromElement.offsetHeight}px`;
		toElementOffsetHeight = `${toElement.offsetHeight}px`;
	}

	/**
	 * In case the new position changed horizontally
	 */
	if (fromElementLeftPosition !== toElementLeftPosition) {
		fromElementOffsetWidth = `${fromElement.offsetWidth}px`;
		toElementOffsetWidth = `${toElement.offsetWidth}px`;
	}

	/**
	 * Sets the offset to be used on Y-axis translate
	 */
	if (toElementTopPosition >= fromElementTopPosition) {
		fromElementOffsetHeight = `+${fromElementOffsetHeight}`;
		toElementOffsetHeight = `-${toElementOffsetHeight}`;
	} else {
		fromElementOffsetHeight = `-${fromElementOffsetHeight}`;
		toElementOffsetHeight = `+${toElementOffsetHeight}`;
	}

	/**
	 * Sets the offset to be used on X-axis translate
	 */
	if (fromElementLeftPosition >= toElementLeftPosition) {
		fromElementOffsetWidth = `-${fromElementOffsetWidth}`;
		toElementOffsetWidth = `+${toElementOffsetWidth}`;
	} else {
		fromElementOffsetWidth = `+${fromElementOffsetWidth}`;
		toElementOffsetWidth = `-${toElementOffsetWidth}`;
	}

	fromElement.style.transform = `translate3d(${fromElementOffsetWidth}, ${fromElementOffsetHeight}, 0px)`;
	toElement.style.transform = `translate3d(${toElementOffsetWidth}, ${toElementOffsetHeight}, 0px)`;

	await new Promise((resolve) => {
		setTimeout(() => {
			fromElement.style.transitionDuration = "0ms";
			toElement.style.transitionDuration = "0ms";

			fromElement.style.transform = "none";
			toElement.style.transform = "none";

			resolve(true);
		}, ANIMATION_IN_MILLISECONDS);
	});
};

// eslint-disable-next-line
export function changeItemOrder<ArrayType extends Array<any>> (
	array: ArrayType | null | undefined,
	fromOrder: number | null | undefined,
	toOrder: number | null | undefined
): ArrayType | null | undefined {
	if (!array || fromOrder === undefined || toOrder === undefined) {
		return array;
	}

	let changedArray = [...array];

	const fromItemIndex = changedArray.findIndex(item => item.order === fromOrder);
	const toItemIndex = changedArray.findIndex(item => item.order === toOrder);

	changedArray = changedArray.map((data, index) => {
		if (index === fromItemIndex) {
			data.order = toOrder;
		}

		if (index === toItemIndex) {
			data.order = fromOrder;
		}

		return data;
	});

	return changedArray as ArrayType;
}

export const getMinMaxFromArray = (array: number[]) => {
	return array.reduce<{ min: number, max: number }>((acc, item) => ({
		min: Math.min(acc.min, item),
		max: Math.max(acc.max, item)
	}), {
		min: Infinity,
		max: -Infinity
	});
};
