import { makeStyles } from "@material-ui/core";

import colors from "../../styles/colors";

type UseStylesProps = {
	topSpacing: number
	lateralSpacing: number
}

const useStyles = makeStyles(theme => {
	const saveButtonHeight = 56;
	const drawerBackgroundColor = colors.palette.background;
	const drawerMaxWidth = 444;

	return {
		closeIcon: {
			position: "absolute",
			top: 0,
			right: 0
		},
		loading: {
			color: colors.greyScale[11]
		},
		drawerPaper: {
			maxWidth: 444,
			width: "100%",
			backgroundColor: drawerBackgroundColor,
			overflowX: "hidden"
		},
		drawerContainer: {
			flex: "1 0 auto",
			alignContent: "space-between",
			marginBottom: saveButtonHeight
		},
		drawerContent: {
			flex: "1 0 auto",
			padding: theme.spacing(3)
		},
		drawerTitle: {
			color: colors.greyScale[1]
		},
		drawerDescription: {
			color: colors.greyScale[1]
		},
		saveButtonContainer: {
			backgroundColor: drawerBackgroundColor,
			position: "fixed",
			bottom: 0,
			height: saveButtonHeight,
			maxWidth: drawerMaxWidth
		},
		saveButton: {
			height: saveButtonHeight,
			width: "100%",
			borderRadius: 0,
			fontWeight: "bolder"
		},
		childrenContainer: {
			pointerEvents: "none",
			padding: theme.spacing(1)
		},
		dashedBorder: {
			position: "relative",
			"&::before": {
				position: "absolute",
				content: "close-quote",
				display: "flex",
				width: (props: UseStylesProps) => `calc(100% - ${props.lateralSpacing * 2}px)`,
				height: (props: UseStylesProps) => `calc(100% - ${props.topSpacing}px)`,
				top: (props: UseStylesProps) => props.topSpacing,
				left: (props: UseStylesProps) => `${props.lateralSpacing}px`,
				border: `1px dashed ${theme.palette.text.secondary}`,
				zIndex: 3
			}
		}
	};
});

export default useStyles;
