import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  InputLabel,
  Grid,
  Link as MaterialLink,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import useStyles from "@/@organizations/Bagy/pages/SignUp/styles";
import useValidation from "@/hooks/useValidation";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import AuthService from "@/services/Auth";
import GoogleTagManagerService from "@/services/GoogleTagManager";

import { Divider, SlugInput } from "@/components";

import { formatEmail, formatPhoneNumber } from "@/utils/mask";

import { useGlobalStateStore } from "@/store/GlobalState";

type FormData = {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  slug: string;
  segmentation: number;
};

const SEGMENTATION = [
  {
    value: 1,
    text: "Loja Virtual",
  },
  {
    value: 2,
    text: "Influencer",
  },
  {
    value: 3,
    text: "Afiliado",
  },
  {
    value: 4,
    text: "Infoproduto",
  },
  {
    value: 5,
    text: "Serviço",
  },
  {
    value: 6,
    text: "Atacado",
  },
  {
    value: 7,
    text: "Outros",
  },
];

const SignUp: React.FC = () => {
  const { setSignupDataAmplitude } = useGlobalStateStore();
  const [formData, setFormData] = useState({} as FormData);
  const [slugSuggestion, setSlugSuggestion] = useState("");
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const validation = useValidation();

  const handleChangeFormData = (
    key: keyof FormData,
    value: string | number
  ) => {
    validation.clearValidation(key);

    setFormData((lastState) => ({
      ...lastState,
      [key]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    try {
      const result = await ApiService.post("/bagy/signup", {
        ...formData,
        utmVariables: GoogleTagManagerService.UTMVariables,
      });

      const { text } = SEGMENTATION[formData.segmentation - 1];

      GoogleTagManagerService.addDataLayerVariables({
        sign_up_success: true,
      });

      const authToken = result.data.authToken;
      setSignupDataAmplitude({
        nome: formData.name,
        email: formData.email,
        telefone: formData.phoneNumber,
        "segmento da loja": text,
      });
      await AuthService.login(authToken, "/site/list");
    } catch (error) {
      validation.triggerValidation(error);

      setSlugSuggestion(error?.response?.data?.slugSuggestion);

      ErrorHandlerService.handle(error);
    }

    setLoading(false);
  };

  const handleUseSlugSuggestion = () => {
    handleChangeFormData("slug", slugSuggestion);
    setSlugSuggestion("");
  };

  return (
    <Grid component="form" className={classes.form} onSubmit={handleSubmit}>
      {Object.entries(GoogleTagManagerService.UTMVariables).map(
        ([key, value]) => (
          <input key={key} type="hidden" name={key} value={value} />
        )
      )}

      <Grid>
        <Typography variant="h3">Cadastre-se</Typography>

        <Typography variant="body1">
          Já tem conta?{" "}
          <MaterialLink component={Link} to="/bagy/signin">
            Faça login
          </MaterialLink>
        </Typography>
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>Nome</InputLabel>
        <TextField
          placeholder="Digite seu nome"
          onChange={({ target }) => handleChangeFormData("name", target.value)}
          value={formData.name}
          helperText={validation.messages.name}
          error={Boolean(validation.messages.name)}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>E-mail</InputLabel>
        <TextField
          placeholder="Seu melhor e-mail"
          onChange={({ target }) => handleChangeFormData("email", target.value)}
          value={formatEmail(formData.email)}
          helperText={validation.messages.email}
          error={Boolean(validation.messages.email)}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>Senha</InputLabel>
        <TextField
          placeholder="Senha"
          onChange={({ target }) =>
            handleChangeFormData("password", target.value)
          }
          value={formatEmail(formData.password)}
          helperText={validation.messages.password}
          error={Boolean(validation.messages.password)}
          variant="outlined"
          type="password"
          fullWidth
        />
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>Telefone</InputLabel>
        <TextField
          placeholder="(00) 0 0000-0000"
          onChange={({ target }) =>
            handleChangeFormData("phoneNumber", target.value)
          }
          value={formatPhoneNumber(formData.phoneNumber)}
          helperText={validation.messages.phoneNumber}
          error={Boolean(validation.messages.phoneNumber)}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>Personalize o link da bio</InputLabel>
        <SlugInput
          slugSuggestion={slugSuggestion}
          onUseSlugSuggestion={handleUseSlugSuggestion}
          onChange={(slug) => handleChangeFormData("slug", slug)}
          validationMessage={validation.messages.slug}
          value={formData.slug}
          hideHelperTextPlaceholder
        />
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Grid>
        <InputLabel>Segmento</InputLabel>
        <Select
          error={Boolean(validation.messages.segmentation)}
          variant="outlined"
          defaultValue=""
          displayEmpty
          fullWidth
        >
          {SEGMENTATION.map((segmentation) => (
            <MenuItem
              key={segmentation.value}
              value={segmentation.value}
              onClick={() =>
                handleChangeFormData("segmentation", segmentation.value)
              }
            >
              {segmentation.text}
            </MenuItem>
          ))}

          <MenuItem value="" disabled>
            Selecione uma categoria
          </MenuItem>
        </Select>
        <FormHelperText
          error={Boolean(validation.messages.segmentation)}
          className={classes.selectHelperText}
        >
          {validation.messages.segmentation}
        </FormHelperText>
      </Grid>

      <Divider orientation="horizontal" size={3} />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disableElevation
        fullWidth
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} />}
      >
        Cadastrar
      </Button>
    </Grid>
  );
};

export default SignUp;
