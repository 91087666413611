import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Typography,
	TextField,
	Button,
	InputLabel,
	Grid,
	CircularProgress
} from "@material-ui/core";

import useStyles from "@/pages/Login/styles";
import useValidation from "@/hooks/useValidation";
import useQuery from "@/hooks/useQuery";
import useOrganization from "@/hooks/useOrganization";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { Divider, Notification } from "@/components";

type FormData = {
	password: string
}

const Reset: React.FC = () => {
	const [formData, setFormData] = useState({} as FormData);
	const [loading, setLoading] = useState(false);

	const classes = useStyles();
	const validation = useValidation();
	const query = useQuery();
	const history = useHistory();
	const organization = useOrganization();

	const handleChangeFormData = (key: keyof FormData, value: string) => {
		validation.clearValidation(key);

		setFormData(lastState => ({
			...lastState,
			[key]: value
		}));
	};

	const getResetPasswordToken = () => {
		const resetPasswordToken = query.get("resetPasswordToken") || "";

		const lastChar = resetPasswordToken[resetPasswordToken.length - 1];

		const isInvalidLastChar = lastChar === ".";

		/**
		 * We have to validate it since some organization mails (such as LastLink)
		 * adds a point in the end of the hash, so it gets wrong.
		 */
		if (isInvalidLastChar) {
			const resetPasswordTokenParams = resetPasswordToken.split("");

			resetPasswordTokenParams[resetPasswordTokenParams.length - 1] = "";

			const validResetPasswordToken = resetPasswordTokenParams.join("");

			return validResetPasswordToken;
		} else {
			return resetPasswordToken;
		}
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		setLoading(true);

		try {
			const resetPasswordToken = getResetPasswordToken();

			await ApiService.put("/auth/password/recovery/reset", {
				...formData,
				resetPasswordToken
			});

			Notification.success({
				message: "Senha alterada com sucesso!"
			});

			history.push(organization.data.signInRoute);
		} catch (error) {
			validation.triggerValidation(error);
			ErrorHandlerService.handle(error);
		}

		setLoading(false);
	};

	return (
		<Grid
			component="form"
			className={classes.form}
			onSubmit={handleSubmit}
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Alterar senha
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid>
				<InputLabel>Nova senha</InputLabel>
				<TextField
					placeholder="Nova senha"
					onChange={({ target }) => handleChangeFormData("password", target.value)}
					value={formData.password}
					helperText={validation.messages.password}
					error={Boolean(validation.messages.password)}
					variant="outlined"
					type="password"
					fullWidth
				/>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Button
				variant="contained"
				color="primary"
				type="submit"
				disableElevation
				fullWidth
				disabled={loading}
				endIcon={loading && (
					<CircularProgress
						size={20}
					/>
				)}
			>
				ALTERAR SENHA
			</Button>
		</Grid>
	);
};

export default Reset;
