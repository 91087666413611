import React, { useEffect } from "react";

import { ThemeProvider } from "@material-ui/core";

import Routes from "@/routes";

import { AppContainer } from "@/components";

import appConfig from "@/config/app";

import useOrganization from "@/hooks/useOrganization";

import amplitude from "amplitude-js";

import {
  Customer,
  SignupDataAmplitudeProps,
  useGlobalStateStore,
} from "@/store/GlobalState";

import ReactGA from "react-ga";

import { initializeApp } from "@firebase/app";
import firebaseCredentials from "@/config/firebaseCredentials";
import analyticsCredentials from "@/config/analyticsCredentials";

const App = () => {
  const globalStateStore = useGlobalStateStore();
  const { signupDataAmplitude } = useGlobalStateStore();
  const organization = useOrganization();
  amplitude.getInstance().init(analyticsCredentials.amplitudeKey || "");
  ReactGA.initialize(analyticsCredentials.analyticsId || "");
  initializeApp(firebaseCredentials);

  // useEffect(() => {
  //   amplitude.getInstance().init(analyticsCredentials.amplitudeKey || "");
  //   ReactGA.initialize(analyticsCredentials.analyticsId || "");
  //   initializeApp(firebaseCredentials);
  // }, []);

  useEffect(() => {
    const setUserAmplitude = (
      signupDataAmplitude: SignupDataAmplitudeProps | undefined,
      userId: number
    ) => {
      const { setSignupDataAmplitude } = globalStateStore;
      if (signupDataAmplitude && userId) {
        amplitude.getInstance().setUserProperties(signupDataAmplitude);
        setSignupDataAmplitude(undefined);
      }
    };

    const setUserIdAmplitude = (customer: Customer) => {
      if (customer.id) {
        amplitude
          .getInstance()
          .setUserId(customer.id.toString().padStart(5, "0"));
      } else amplitude.getInstance().setUserId(null);
    };
    setUserIdAmplitude(globalStateStore.customer);
    setUserAmplitude(signupDataAmplitude, globalStateStore.customer.id);
  }, [globalStateStore, globalStateStore.customer.id, signupDataAmplitude]);

  return (
    <ThemeProvider theme={organization.theme}>
      <AppContainer id={appConfig.appContainerId}>
        <Routes />
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
