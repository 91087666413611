import React from "react";
import {
	Grid,
	ButtonBase,
	Fab,
	Zoom,
	Typography
} from "@material-ui/core";
import {
	CheckOutlined as SelectedIcon
} from "@material-ui/icons";

import useStyles from "@/components/ImageSelect/styles";

type Item = {
	imageSrc?: string
	// eslint-disable-next-line
	value: any
	label?: string
}

type ImageSelectProps = {
	items?: Array<Item>
	// eslint-disable-next-line
	onChange?: (value: any) => void
	// eslint-disable-next-line
	value?: any
	buttonStyle?: React.CSSProperties
	render?: (item: Item) => React.ReactNode
	classes?: {
		selectedButton?: string
		buttonContainer?: string
		buttonGroupContainer?: string
		selectedIcon?: string
	}
}

const ImageSelect: React.FC<ImageSelectProps> = (props) => {
	const {
		items,
		onChange,
		value,
		buttonStyle,
		render,
		classes: customClasses
	} = props;

	const classes = useStyles();

	return (
		<Grid
			container
			spacing={2}
			className={customClasses?.buttonGroupContainer}
		>
			{items?.map(item => {
				const isSelected = item.value === value;

				return (
					<Grid
						key={item.value}
						item
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						className={`${classes.buttonContainer} ${customClasses?.buttonContainer}`}
					>
						<Grid
							container
							alignItems="center"
							justify="center"
						>
							<Grid
								container
								component={ButtonBase}
								onClick={() => onChange && onChange(item.value)}
								className={`${classes.button} ${isSelected && `${classes.selectedButton} ${customClasses?.selectedButton}`}`}
								style={buttonStyle || {}}
							>
								{render ? (
									render(item)
								) : (
									<img
										src={item.imageSrc}
										alt={item.value}
										className={classes.img}
									/>
								)}

								<Zoom in={isSelected}>
									<Fab
										size="small"
										className={`${classes.selectedIcon} ${customClasses?.selectedIcon}`}
									>
										<SelectedIcon
											color="primary"
										/>
									</Fab>
								</Zoom>
							</Grid>

							<Typography
								variant="h2"
								className={classes.label}
							>
								{item.label}
							</Typography>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default ImageSelect;
