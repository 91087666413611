import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import { SiteData } from "@/protocols/site";

class SiteService {
	async getSiteDataOrThrow (siteId: number): Promise<SiteData> {
		const response = await ApiService.get("/site/data", {
			params: {
				siteId
			}
		});

		const siteData = response.data;

		return siteData;
	}

	async getSiteData (siteId: number): Promise<SiteData | null> {
		try {
			const response = await ApiService.get("/site/data", {
				params: {
					siteId
				}
			});

			const siteData = response.data;

			return siteData;
		} catch (error) {
			ErrorHandlerService.handle(error);
			return null;
		}
	}
}

export default new SiteService();
