import { injectJS, globalVariableExists, getGlobalVariable } from "@/utils/dom";

import { SiteIntegrationsType } from "@/@fp/protocols/Site";

class Integration {
	enableIntegration (type: SiteIntegrationsType, integrationId: string): void {
		if (!integrationId || !type) {
			return;
		}

		const integrators: { [key in SiteIntegrationsType]: () => void } = {
			"facebook-pixel": () => this.enableFacebookPixel(integrationId),
			"google-adwords": () => this.enableGoogleAdwords(integrationId),
			"google-analytics": () => this.enableGoogleAnalytics(integrationId)
		};

		const integrator = integrators[type];

		if (integrator) {
			integrator();
		}
	}

	callTrackerPageView (pathname: string) {
		const googleAnalytics = getGlobalVariable("ga");

		if (googleAnalytics) {
			googleAnalytics("send", "pageview", pathname);
		}
	}

	private enableFacebookPixel (facebookPixelId: string): void {
		const facebookPixelScriptContent = `
			!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '${facebookPixelId}');
				fbq('track', 'PageView');
		`;

		const facebookPixelScriptId = this.buildCustomerScriptId("facebook-pixel");

		injectJS(facebookPixelScriptContent, facebookPixelScriptId);
	}

	private enableGoogleAnalytics (googleAnalyticsId: string): void {
		this.enableGoogleGlobalTag(googleAnalyticsId);

		const googleAnalyticsSecondaryScriptContent = `
			gtag('config', '${googleAnalyticsId}');	
		`;

		const googleAnalyticsSecondaryScriptId = this.buildCustomerScriptId("google-analytics");

		injectJS(googleAnalyticsSecondaryScriptContent, googleAnalyticsSecondaryScriptId);
	}

	private enableGoogleAdwords (googleAdwordsId: string): void {
		this.enableGoogleGlobalTag(googleAdwordsId);

		const googleAnalyticsSecondaryScriptContent = `
			gtag('config', '${googleAdwordsId}');
		`;

		const googleAnalyticsSecondaryScriptId = this.buildCustomerScriptId("google-adwords");

		injectJS(googleAnalyticsSecondaryScriptContent, googleAnalyticsSecondaryScriptId);
	}

	private enableGoogleGlobalTag (id: string): void {
		const isGtagInitialized = globalVariableExists("gtag");

		if (isGtagInitialized) {
			return;
		}

		const googleGlobalTagPrimaryScript = document.createElement("script");
		googleGlobalTagPrimaryScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
		document.body.appendChild(googleGlobalTagPrimaryScript);

		const googleGlobalTagSecondaryScriptContent = `
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());
		`;

		const googleGlobalTagSecondaryScriptId = this.buildCustomerScriptId("google-gtag");

		injectJS(googleGlobalTagSecondaryScriptContent, googleGlobalTagSecondaryScriptId);
	}

	private buildCustomerScriptId (value: string): string {
		const scriptId = `customer@${value}`;

		return scriptId;
	}
}

export default new Integration();
