import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  ButtonBase,
  // Chip
} from "@material-ui/core";

import { PageType } from "@/@fp/protocols/Page";

import { templatePageCategoryMap } from "@/utils/templatePageCategory";

import { Portlet, Divider, PageCard, Loading } from "@/components";

import useDidMount from "@/hooks/useDidMount";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";

import useStyles from "@/pages/TemplatePageCategoryList/styles";

import TemplatePageCategoryListSkeleton from "@/skeletons/TemplatePageCategoryList";

type TemplatePage = {
  id: number;
  type: PageType;
  title: string;
  description: string;
  coverUrl: string;
  loadingCreation: boolean;
};

const TemplatePageCategoryList = () => {
  const classes = useStyles();

  const { siteId } = useParams<{ siteId: string }>();

  const [templatePageCategories, setTemplatePageCategories] = useState<
    TemplatePage[]
  >([]);
  const [loadingTemplatePageCategories, setLoadingTemplatePageCategories] =
    useState(true);

  const getTemplatePageCategories = async () => {
    try {
      const response = await ApiService.get("/site/template-page-categories");

      setTemplatePageCategories(response.data.templatePageCategories);
    } catch (error) {
      ErrorHandlerService.handle(error);
    }

    setLoadingTemplatePageCategories(false);
  };

  useDidMount(() => {
    getTemplatePageCategories();
  });

  return (
    <>
      <Typography variant="h4">Adicionar página</Typography>

      <Divider orientation="horizontal" size={3} />

      <Grid container spacing={2}>
        <Loading
          loading={loadingTemplatePageCategories}
          customLoadingElement={<TemplatePageCategoryListSkeleton />}
        >
          <>
            {templatePageCategories.map((templatePageCategory, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                component={Link}
                to={`/sites/${siteId}/template-page-categories/${templatePageCategory.id}`}
                className={classes.templatePageCategoryContainer}
              >
                <Portlet
                  component={ButtonBase}
                  elevation={1}
                  style={{
                    border: "1px solid #DDE2E5",
                    width: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <PageCard
                        backgroundPictureSrc={templatePageCategory.coverUrl}
                        elevation={0}
                        style={{
                          border: "0.7px solid #DDE2E5",
                        }}
                      />
                    </Grid>

                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid container direction="row">
                            {
                              templatePageCategoryMap[templatePageCategory.type]
                                ?.icon
                            }

                            <Divider orientation="vertical" size={1} />

                            <Typography
                              variant="h4"
                              align="left"
                              className={classes.categoryTitle}
                            >
                              {templatePageCategory.title}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid container>
                            <Typography variant="overline" align="left">
                              {templatePageCategory.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Portlet>
              </Grid>
            ))}

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Portlet
								elevation={1}
								style={{
									border: "1px solid #DDE2E5"
								}}
							>
								<Grid
									container
									spacing={2}
								>
									<Grid container className={classes.comingSoonTemplateContainer}>
										<Chip
											label={(
												<Typography variant="h5">
													EM BREVE
												</Typography>
											)}
											variant="default"
											className={classes.comingSoonChip}
										/>
									</Grid>
									<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
										<PageCard
											backgroundPictureSrc={templatePageCategoryMap?.youtube?.previewImg}
											elevation={0}
											style={{
												...(templatePageCategoryMap?.youtube?.style || {}),
												border: "0.7px solid #DDE2E5"
											}}
										/>
									</Grid>

									<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container direction="row">
													{templatePageCategoryMap?.youtube?.icon}

													<Divider orientation="vertical" size={1} />

													<Typography variant="h4">
														Youtube
													</Typography>
												</Grid>
											</Grid>

											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container>
													<Typography
														variant="overline"
														align="left"
													>
														Adicione um atalho para seu canal no YouTube ou destaque seu último vídeo.
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Portlet>
						</Grid> */}
          </>
        </Loading>
      </Grid>
    </>
  );
};

export default TemplatePageCategoryList;
