import QRCode from "qrcode";

import ErrorHandlerService from "@/services/ErrorHandler";

type QRCodeOptions = {
	width?: number
	quality?: number
	margin?: number
}

class QRCodeService {
	/**
	 * Returns a url of QRCode image
	 */
	async generate (text: string, options?: QRCodeOptions): Promise<string | null> {
		try {
			const url = await QRCode.toDataURL(text, {
				width: options?.width,
				rendererOpts: {
					quality: options?.quality
				},
				margin: options?.margin
			});

			return url;
		} catch (error) {
			ErrorHandlerService.handle(error);
			return null;
		}
	}
}

export default new QRCodeService();
