import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Grid,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";

import { Divider, PageCard, Loading, PlanBlockPopup } from "@/components";

import useDidMount from "@/hooks/useDidMount";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import TemplateService from "@/services/Template";

import { TemplatePage } from "@/protocols/page";

import { useGlobalStateStore } from "@/store/GlobalState";
import useOrganization from "@/hooks/useOrganization";

import { getStatusCode } from "@/utils/response";

import useStyles from "@/pages/TemplatePageCategoryList/TemplatePageList/styles";

import TemplatePageListSkeleton from "@/skeletons/TemplatePageList";

import amplitude from "amplitude-js";
import { getAnalytics, logEvent } from "@firebase/analytics";

const TemplatePageList: React.FC = () => {
  const { siteId, templatePageCategoryId } = useParams<{
    siteId: string;
    templatePageCategoryId: string;
  }>();

  const classes = useStyles();
  const history = useHistory();
  const globalStateStore = useGlobalStateStore();
  const organization = useOrganization();

  const [loadingTemplatePages, setLoadingTemplatePages] = useState(true);
  const [templatePages, setTemplatePages] = useState<TemplatePage[]>([]);

  const TEMPLATE_PAGES = [
    "Lista de links",
    "Whatsapp",
    "Página de Vendas",
    "Vídeo",
    "Portfólio",
    "Cardápio",
  ];

  const getTemplatePages = async () => {
    const templatePages = await TemplateService.getTemplatePagesByCategoryId(
      +templatePageCategoryId
    );

    if (templatePages) {
      setTemplatePages(templatePages);
    }

    setLoadingTemplatePages(false);
  };

  const setLoadingCreation = (templatePageId: number, value: boolean) => {
    setTemplatePages((lastState) =>
      lastState.map((templatePage) => {
        if (templatePage.id === templatePageId) {
          return {
            ...templatePage,
            loadingCreation: value,
          };
        }

        return templatePage;
      })
    );
  };

  const handleCreatePage = async (templatePageId: number) => {
    const pageChoice = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    amplitude
      .getInstance()
      .logEvent(`Página - Criou página "${TEMPLATE_PAGES[+pageChoice - 1]}"`);
    const analytics = getAnalytics();
    logEvent(
      analytics,
      `Página - Criou página "${TEMPLATE_PAGES[+pageChoice - 1]}"`
    );
    setLoadingCreation(templatePageId, true);
    try {
      const response = await ApiService.post(`/site/${siteId}/pages`, {
        templatePageId,
      });

      const pageId = response?.data?.id;

      history.push(`/sites/${siteId}/pages/${pageId}/edit`);
    } catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 402) {
        PlanBlockPopup.open({
          featureAction: "criar mais páginas",
          planButtonLink: organization.mountPlanPageUrl(
            globalStateStore.site.slug
          ),
        });
      }

      ErrorHandlerService.handle(error);
    }

    setLoadingCreation(templatePageId, false);
  };

  const getCurrentPageTitle = () => {
    const isThereAnyProductTemplate = templatePages.some(
      (page) => page.type === "product"
    );

    if (isThereAnyProductTemplate) {
      return "Escolha o layout";
    } else {
      return "Selecione um template";
    }
  };

  useDidMount(() => {
    getTemplatePages();
  });

  return (
    <>
      <Loading
        loading={loadingTemplatePages}
        customLoadingElement={<TemplatePageListSkeleton />}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h4">{getCurrentPageTitle()}</Typography>

            <Divider orientation="horizontal" size={3} />
          </Grid>

          <>
            {templatePages.map((templatePage, index) => (
              <Grid key={index} item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid
                  onClick={() => handleCreatePage(templatePage.id)}
                  className={classes.templateContainer}
                  component={ButtonBase}
                  container
                >
                  {templatePage.loadingCreation && (
                    <Grid container className={classes.templateLoading}>
                      <CircularProgress />
                    </Grid>
                  )}

                  <PageCard
                    backgroundPictureSrc={templatePage.coverUrl}
                    elevation={templatePage.type === "product" ? 2 : 0}
                  />
                </Grid>

                {templatePage?.description && (
                  <Grid container alignItems="center" justify="center">
                    <Divider orientation="horizontal" size={2} />

                    <Typography variant="h2" color="textPrimary" align="center">
                      {templatePage.description}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </>
        </Grid>
      </Loading>
    </>
  );
};

export default TemplatePageList;
