import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: theme.spacing(1)
	},
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	}
}));

export default useStyles;
