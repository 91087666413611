import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	container: {
		display: "flex",
		width: "100%",
		flexDirection: "column"
	}
});

export default useStyles;
