import React from "react";
import { Route } from "react-router-dom";

import Setup from "@/@organizations/Bagy/pages/Setup";

export const PrivateCPRoutes = [
	<Route
		exact
		key="bagy-setup"
		path="/bagy/setup"
		component={Setup}
	/>
];
