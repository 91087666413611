import React, { useEffect, useState } from "react";
import { Grid, Link, Typography, IconButton, Tooltip } from "@material-ui/core";
import {
  SubdirectoryArrowLeft as ArrowIndicatorIcon,
  FileCopyOutlined as CopyIcon,
} from "@material-ui/icons";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import AuthService from "@/services/Auth";
import ErrorHandlerService from "@/services/ErrorHandler";
import SiteService from "@/services/Site";
import IntegrationService from "@/@fp/services/Integration";
import ApiService from "@/services/Api";

import useDidMount from "@/hooks/useDidMount";
import { useGlobalStateStore } from "@/store/GlobalState";
import useShare from "@/hooks/useShare";
import useOrganization from "@/hooks/useOrganization";

import { retrieveSiteBasePath, retrieveSiteId } from "@/utils/path";
import { getStatusCode } from "@/utils/response";
import { loadAllFonts } from "@/utils/css";
import { isFPClient } from "@/utils/app";

import {
  Header,
  Loading,
  Divider,
  InfoDialog,
  PageContainer,
  OrganizationHeader,
} from "@/components";

import useStyles from "@/routes/styles";

import { SitePrivateCPRoutes, PrivateCPRoutes } from "@/routes/private";
import { FPPublicRoutes, CPPublicRoutes } from "@/routes/public";

import DashBoardSkeleton from "@/skeletons/Dashboard";

import amplitude from "amplitude-js";

// import ReactGA from "react-ga";

import { getAnalytics, logEvent } from "@firebase/analytics";

const DefaultPrivateCPComponent = () => {
  return (
    <PageContainer>
      <PrivateCPRoutes />
    </PageContainer>
  );
};

const SitePrivateCPComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const share = useShare();

  const [loading, setLoading] = useState(true);
  const globalStateStore = useGlobalStateStore();

  const screenWidth = window.innerWidth < 444 ? window.innerWidth : 444;

  const isPageList = history.location.pathname.endsWith("/pages");
  const siteFPUrl = globalStateStore?.site?.url;

  const getData = async () => {
    const siteId = retrieveSiteId();

    try {
      const data = await SiteService.getSiteDataOrThrow(siteId as number);

      if (data) {
        globalStateStore.setSiteData(data.site);
        globalStateStore.setCustomerData(data.customer);
        globalStateStore.setOrganizationData(data.organization);
      }

      setLoading(false);
    } catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 403) {
        InfoDialog.open({
          description: "Você não tem permissão para editar este link.",
          closable: false,
        });
      }

      ErrorHandlerService.handle(error);
    }
  };

  const handleGoBack = () => {
    const siteBasePath = retrieveSiteBasePath();

    const isPageList = history.location.pathname.endsWith("/pages");
    const isTemplatePageList = history.location.pathname.includes(
      "/template-page-categories"
    );

    const canGoBackHistory = history.length > 0;

    if (isPageList) {
      history.push("/site/list");
    } else if (isTemplatePageList && canGoBackHistory) {
      history.goBack();
    } else {
      history.push(`${siteBasePath}/pages`);
    }
  };

  const handleCopySiteLink = () => {
    amplitude.getInstance().logEvent("Link - Copiou link");
    const analytics = getAnalytics();
    logEvent(analytics, "Link - Copiou link");
    share.copySiteFPUrl();
  };

  useDidMount(() => {
    getData();
  });

  return (
    <Loading loading={loading} customLoadingElement={<DashBoardSkeleton />}>
      {AuthService.isLoggedIn ? (
        <>
          {isPageList ? (
            <Header
              onBackButtonClick={handleGoBack}
              backButtonStyle={{
                transform: "translateY(2px)",
              }}
              style={{
                height: 116,
                alignContent: "flex-start",
              }}
            >
              <Grid
                container
                justify="space-between"
                className={classes.headerContent}
              >
                <Grid
                  container
                  justify="center"
                  className={classes.linkContainer}
                >
                  <Grid item>
                    <Link
                      className={classes.link}
                      href={siteFPUrl}
                      target="_blank"
                      color="primary"
                      style={{
                        maxWidth: screenWidth * 0.6,
                      }}
                    >
                      {siteFPUrl}
                    </Link>

                    <Divider orientation="horizontal" size={0.5} />

                    <Grid item>
                      <ArrowIndicatorIcon
                        fontSize="small"
                        className={classes.arrowIndicator}
                      />

                      <Typography variant="overline">
                        Este é o seu link.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Tooltip title="Clique aqui para copiar o link do seu site.">
                  <IconButton
                    className={classes.copyButton}
                    onClick={handleCopySiteLink}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Header>
          ) : (
            <Header onBackButtonClick={handleGoBack}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                  flex: 1,
                }}
              >
                <Grid
                  container
                  justify="center"
                  className={classes.linkContainer}
                >
                  <Grid item>
                    <Link
                      className={classes.link}
                      href={siteFPUrl}
                      target="_blank"
                      color="primary"
                      style={{
                        maxWidth: screenWidth * 0.6,
                      }}
                    >
                      {siteFPUrl}
                    </Link>
                  </Grid>
                </Grid>

                <IconButton
                  className={classes.copyButton}
                  style={{
                    opacity: 0,
                    pointerEvents: "none",
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Grid>
            </Header>
          )}

          <Grid container direction="column" className={classes.container}>
            <SitePrivateCPRoutes />
          </Grid>
        </>
      ) : (
        <h1>É necessário estar logado para acessar essa página.</h1>
      )}
    </Loading>
  );
};

const PrivateCPComponent = () => {
  const [loading, setLoading] = useState(true);

  const globalStateStore = useGlobalStateStore();
  const classes = useStyles();

  const load = async () => {
    try {
      const { data } = await ApiService.get("/customer/initial-data");

      globalStateStore.setCustomerData(data.customer);
      globalStateStore.setOrganizationData(data.organization);

      setLoading(false);
    } catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 401) {
        window.location.pathname = "/bagy/signin";
      } else if (statusCode === 402) {
        InfoDialog.open({
          description: "Sua conta está inativa.",
          closable: false,
        });
      }

      ErrorHandlerService.handle(error);
    }
  };

  useDidMount(() => {
    load();
  });

  return (
    <Grid container className={classes.defaultPrivateComponentContainer}>
      <Loading loading={loading}>
        <Grid container direction="column">
          <OrganizationHeader />

          <Switch>
            <Route path="/sites/:siteId" component={SitePrivateCPComponent} />
            <Route path="/" component={DefaultPrivateCPComponent} />
          </Switch>
        </Grid>
      </Loading>
    </Grid>
  );
};

const Routes = () => {
  const history = useHistory();
  const organization = useOrganization();

  loadAllFonts();
  organization.setup();

  history.listen(({ pathname }) => {
    IntegrationService.callTrackerPageView(pathname);
  });

  const location = useLocation();

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    const analytics = getAnalytics();
    logEvent(analytics, "screen_view");
  }, [location]);

  if (isFPClient()) {
    return <Switch>{FPPublicRoutes}</Switch>;
  }

  return (
    <Switch>
      {organization?.routes?.public?.cp}
      {CPPublicRoutes}
      <Route path="/" component={PrivateCPComponent} />
    </Switch>
  );
};

export default Routes;
