import React, { useState } from "react";
import {
	Dialog,
	Grid,
	Typography,
	Button,
	ThemeProvider,
	IconButton
} from "@material-ui/core";
import {
	Close as CloseIcon
	// DoneOutlined as CheckIcon
} from "@material-ui/icons";

import theme from "@/styles/theme";

import { renderComponent } from "@/utils/node";
import { isMobile } from "@/utils/checkDevice";
import { handleBackButton } from "@/utils/backButton";
// import { isLastItem } from "@/utils/array"

import useDidMount from "@/hooks/useDidMount";
import useOrganization from "@/hooks/useOrganization";

import { Divider } from "@/components";

import useStyles from "@/components/PlanBlockPopup/styles";

type PlanBlockPopupProps = {
	featureAction?: string
	planButtonLink: string
}

type PlanBlockPopupType = {
	open: (props: PlanBlockPopupProps) => void
}

const PlanBlockPopup: PlanBlockPopupType & React.FC<PlanBlockPopupProps> = (props) => {
	const {
		featureAction,
		planButtonLink
	} = props;

	const classes = useStyles();
	const organization = useOrganization();

	const [opened, setOpened] = useState(true);

	const handleClose = () => {
		setOpened(false);
	};

	useDidMount(() => {
		if (isMobile) {
			handleBackButton(
				() => handleClose()
			);
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
			>
				<Grid
					container
					className={classes.dialogContainer}
				>
					<IconButton onClick={handleClose} className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>

					<Typography
						variant="h4"
						color="textPrimary"
					>
						Faça o upgrade do seu plano
					</Typography>

					<Divider orientation="horizontal" size={2} />

					<Typography
						variant="body1"
						color="textPrimary"
					>
						Assine o Plano {organization.plans.Professional.name} para poder {featureAction} e deixar o seu site com a cara do seu negócio.
					</Typography>

					<Divider orientation="horizontal" size={2} />

					{/* <Typography
						variant="body1"
						color="textPrimary"
					>
						O Plano {organization.plans.Professional.name} inclui:
					</Typography>

					<Divider orientation="horizontal" size={2} />

					{organization.plans.Professional.features.map((feature, index) => (
						<>
							<Typography
								variant="overline"
								color="textPrimary"
								className={classes.featuresText}
							>
								<CheckIcon color="primary" />

								<Divider orientation="vertical" size={1} />

								{feature}
							</Typography>

							{!isLastItem(index, organization.plans.Professional.features.length) && (
								<Divider orientation="horizontal" size={1} />
							)}
						</>
					))}

					<Divider orientation="horizontal" size={4} /> */}

					<Grid
						container
						justify="center"
					>
						<Button
							variant="contained"
							color="primary"
							href={planButtonLink}
							onClick={handleClose}
							disableElevation
							{...({ target: "_blank" })}
						>
							ASSINAR
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	);
};

PlanBlockPopup.open = (props: PlanBlockPopupProps) => {
	renderComponent(
		"plan-block-popup",
		<PlanBlockPopup
			{...props}
		/>
	);
};

export default PlanBlockPopup;
