import React, { useState } from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  InputLabel,
  Button,
} from "@material-ui/core";
import {
  ArrowForwardIos as ArrowRightIcon,
  HelpOutlineOutlined as HelpIcon,
} from "@material-ui/icons";

import {
  EditDrawer,
  Portlet,
  PlanBlockPopup,
  ActionButton,
  Loading,
  SlugInput,
  PopConfirm,
  SuccessModal,
  InfoDialog,
} from "@/components";

import ApiService from "@/services/Api";
import ErrorHandlerService from "@/services/ErrorHandler";
import SiteService from "@/services/Site";

import {
  formatFacebookPixelID,
  formatGooglePixelID,
  formatGoogleAnalyticsID,
} from "@/utils/mask";
import { getStatusCode } from "@/utils/response";
import { replaceSlugInUrlTemplate } from "@/utils/url";

import useStyles from "@/pages/PageList/SiteSettingsEditDrawer/styles";
import useCustomStyles from "@/styles/custom";

import useDidMount from "@/hooks/useDidMount";
import { useGlobalStateStore } from "@/store/GlobalState";
import useValidation from "@/hooks/useValidation";
import useOrganization from "@/hooks/useOrganization";

import { SiteIntegrationsType } from "@/@fp/protocols/Site";

import SiteSettingsEditDrawerSkeleton from "@/skeletons/SiteSettingsEditDrawer";

import amplitude from "amplitude-js";
import { useHistory } from "react-router-dom";
import { getAnalytics, logEvent } from "@firebase/analytics";
// import ReactGA from "react-ga";

type SiteIntegrations = {
  [key in SiteIntegrationsType]: {
    id: number;
    integrationId: string;
  };
};

type SiteSettings = {
  brandSettings: {
    id: number;
    organizationBrand: boolean;
    hasAccessToChangeBrand: boolean;
  };
};

type SiteData = {
  title: string;
  active: boolean;
  slug: string;
  url: string;
};

type IntegrationListItem = {
  name: string;
  type: SiteIntegrationsType;
  inputLabel: string;
  inputHelperText: string;
  inputPlaceholder: string;
  inputMask: (value: string) => string;
};

const integrationListItems: IntegrationListItem[] = [
  {
    name: "Pixel do Facebook",
    type: "facebook-pixel",
    inputLabel: "ID do pixel",
    inputHelperText: "Exemplo: 00046701245236",
    inputPlaceholder: "XXXXXXXXXXXXX",
    inputMask: formatFacebookPixelID,
  },
  {
    name: "Pixel do Adwords",
    type: "google-adwords",
    inputLabel: "ID do pixel",
    inputHelperText: "Exemplo: AW-5Jlhm235/12jfHsLs023",
    inputPlaceholder: "AW-XXXXXXXXX/XXXXXXXXX",
    inputMask: formatGooglePixelID,
  },
  {
    name: "Google Analytics",
    type: "google-analytics",
    inputLabel: "ID do analytics",
    inputHelperText: "Exemplo: UA-7AB34OTL-1",
    inputPlaceholder: "UA-XXXXXXXX-1",
    inputMask: formatGoogleAnalyticsID,
  },
];

type SiteSettingsEditDrawerProps = {
  siteId: number;
};

const SiteSettingsEditDrawer: React.FC<SiteSettingsEditDrawerProps> = (
  props
) => {
  const { siteId } = props;

  const classes = useStyles();
  const customClasses = useCustomStyles();
  const globalStateStore = useGlobalStateStore();
  const validation = useValidation();
  const organization = useOrganization();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [siteSettings, setSiteSettings] = useState<SiteSettings>(
    {} as SiteSettings
  );
  const [siteIntegrations, setSiteIntegrations] = useState<SiteIntegrations>(
    {} as SiteIntegrations
  );
  const [siteData, setSiteData] = useState<SiteData>({} as SiteData);
  const [slugSuggestion, setSlugSuggestion] = useState<string>("");

  /**
   * Hard coded to false since all current MiniSite plans has
   * this feature available.
   */
  const removeBrandEnabled = false;

  const getSiteData = async (): Promise<SiteData | null> => {
    const siteData = await SiteService.getSiteData(siteId);

    if (siteData) {
      setSiteData(siteData.site);
    }

    return siteData?.site as SiteData;
  };

  const getSiteIntegrations = async () => {
    try {
      const integrationTypes = integrationListItems.map(
        (integration) => integration.type
      );

      const mergedIntegrationTypes = integrationTypes.join(",");

      const response = await ApiService.get("/site/integrations", {
        params: {
          types: mergedIntegrationTypes,
          siteId,
        },
      });

      const integrations = response.data;

      setSiteIntegrations({ ...integrations });
    } catch (error) {
      ErrorHandlerService.handle(error);
    }
  };

  const getSiteSettings = async () => {
    try {
      const response = await ApiService.get("/site/settings", {
        params: {
          siteId,
        },
      });

      const siteSettings = response.data;

      setSiteSettings(siteSettings);
    } catch (error) {
      ErrorHandlerService.handle(error);
    }
  };

  const handleChangeIntegrationId = (
    siteIntegrationId: number,
    integrationId: string
  ): void => {
    const updatedSiteIntegrations = { ...siteIntegrations };

    Object.entries(siteIntegrations).forEach(([key, data]) => {
      const integrationType = key as SiteIntegrationsType;

      if (data.id === siteIntegrationId) {
        updatedSiteIntegrations[integrationType].integrationId = integrationId;
      }
    });

    setSiteIntegrations(updatedSiteIntegrations);
  };

  const handleSaveIntegrationId = async (
    siteIntegrationId: number,
    integrationName: string
  ) => {
    let integrationId = "";

    Object.values(siteIntegrations).forEach((integration) => {
      if (integration.id === siteIntegrationId) {
        integrationId = integration.integrationId;
      }
    });

    try {
      await ApiService.put(`/site/integrations/${siteIntegrationId}`, {
        integrationId,
      });
      amplitude.getInstance().logEvent(`Pixel - Inseriu ${integrationName}`);
      const analytics = getAnalytics();
      logEvent(analytics, `Pixel - Inseriu ${integrationName}`);
      return true;
    } catch (error) {
      ErrorHandlerService.handle(error);
      return false;
    }
  };

  const handleSaveSlug = async () => {
    const { slug } = siteData;
    try {
      await ApiService.put(`/site/${siteId}/slug`, {
        slug,
      });
      const eventProperties = {
        "Url antiga": globalStateStore?.site?.slug,
        "Nova url": slug,
      };
      amplitude
        .getInstance()
        .logEvent("Link - Editou a url do link", eventProperties);
      const analytics = getAnalytics();
      logEvent(analytics, "Link - Editou a url do link", eventProperties);
      // eslint-disable-next-line no-undef
      // ReactGA.event({
      //   category: "Link",
      //   action: "Editou a url do link",
      //   ...eventProperties,
      // });
      const siteData = await getSiteData();
      if (siteData) {
        globalStateStore.setSiteData({
          url: siteData.url,
          slug: siteData.slug,
        });
      }

      return true;
    } catch (error) {
      ErrorHandlerService.handle(error);

      const statusCode = getStatusCode(error);

      if (statusCode === 402) {
        PlanBlockPopup.open({
          featureAction: "alterar seu link",
          planButtonLink: organization.mountPlanPageUrl(
            globalStateStore.site.slug
          ),
        });
      } else {
        setSlugSuggestion(error?.response?.data?.suggestion);

        validation.triggerValidation(error);
      }

      return false;
    }
  };

  const handleChangeSlug = (slug: string) => {
    setSiteData((lastState) => ({
      ...lastState,
      slug,
    }));

    validation.clearValidation("slug");
  };

  const handleUseSlugSuggestion = () => {
    const suggestion = slugSuggestion;

    handleChangeSlug(suggestion);
    setSlugSuggestion("");
  };

  const handleChangeOrganizationBrand = async (value: boolean) => {
    const settingsBackup = { ...siteSettings };

    setSiteSettings((lastState) => ({
      ...lastState,
      brandSettings: {
        ...lastState.brandSettings,
        organizationBrand: value,
      },
    }));

    try {
      await ApiService.put(
        `/site/${siteId}/settings/${siteSettings.brandSettings.id}/organization-brand`,
        {
          organizationBrandValue: value,
        }
      );
    } catch (error) {
      const statusCode = getStatusCode(error);

      setSiteSettings(settingsBackup);

      ErrorHandlerService.handle(error);

      if (statusCode === 402) {
        PlanBlockPopup.open({
          featureAction: `remover a marca do ${organization.data.name}`,
          planButtonLink: organization.mountPlanPageUrl(
            globalStateStore.site.slug
          ),
        });
      }
    }
  };

  const handleChangeSiteData = (key: keyof SiteData, value: unknown) => {
    setSiteData((lastState) => ({
      ...lastState,
      [key]: value,
    }));
  };

  const handleSaveSiteTitle = async () => {
    try {
      await ApiService.put(`/site/${siteId}`, {
        title: siteData.title,
      });

      return true;
    } catch (error) {
      validation.triggerValidation(error);

      return false;
    }
  };

  const handleChangeActive = async (value: boolean) => {
    const active = value;

    setSiteData((lastState) => ({
      ...lastState,
      active,
    }));

    await ApiService.put(`/site/${siteId}`, {
      active,
    });
  };

  const handleDeleteSite = async (id: number) => {
    try {
      await ApiService.delete("/site", {
        params: {
          siteId: id,
        },
      });
      SuccessModal.open({});
      history.push("/site/list");
    } catch (error) {
      ErrorHandlerService.handle(error);
      const statusCode = getStatusCode(error);
      if (statusCode === 403) {
        InfoDialog.open({
          description: "Você não tem permissão para apagar este link.",
          closable: true,
        });
        return;
      }
      InfoDialog.open({
        description:
          "Desculpe, um erro inesperado ocorreu, entre em contato com o suporte.",
        closable: true,
      });
    }
  };

  const getData = async () => {
    await Promise.all([
      getSiteSettings(),
      getSiteIntegrations(),
      getSiteData(),
    ]);

    setLoading(false);
  };

  useDidMount(() => {
    getData();
  });

  return (
    <EditDrawer
      title="Configurar link"
      description="Defina as configurações referentes ao seu link."
      hideSaveButton
      formComponent={
        <Loading
          loading={loading}
          customLoadingElement={<SiteSettingsEditDrawerSkeleton />}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Portlet elevation={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="button">DADOS DO LINK</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List disablePadding>
                        <EditDrawer
                          title="Editar link"
                          description="Escolha um novo link."
                          onSave={handleSaveSlug}
                          formComponent={
                            <Portlet elevation={1}>
                              <InputLabel>Link</InputLabel>
                              <SlugInput
                                value={siteData?.slug}
                                onChange={(slug) => handleChangeSlug(slug)}
                                onUseSlugSuggestion={() =>
                                  handleUseSlugSuggestion()
                                }
                                validationMessage={validation.messages?.slug}
                                slugSuggestion={slugSuggestion}
                              />
                            </Portlet>
                          }
                          pureChildren
                        >
                          <ListItem button disableGutters>
                            <ListItemText
                              primary="Link Atual"
                              secondary={replaceSlugInUrlTemplate(
                                organization.data.changeSlugHost,
                                globalStateStore?.site?.slug
                              )}
                            />

                            <ArrowRightIcon
                              className={customClasses.listItemIcon}
                              fontSize="small"
                            />
                          </ListItem>
                        </EditDrawer>
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List disablePadding>
                        <EditDrawer
                          title="Editar nome"
                          description="Escolha um novo nome para o seu link."
                          onSave={handleSaveSiteTitle}
                          formComponent={
                            <Portlet elevation={1}>
                              <InputLabel>Nome</InputLabel>
                              <TextField
                                placeholder="Nome do link"
                                variant="outlined"
                                value={siteData.title}
                                onChange={({ target }) =>
                                  handleChangeSiteData("title", target.value)
                                }
                                fullWidth
                              />
                            </Portlet>
                          }
                          pureChildren
                        >
                          <ListItem button disableGutters>
                            <ListItemText
                              primary="Nome do link"
                              secondary={siteData.title}
                            />

                            <ArrowRightIcon
                              className={customClasses.listItemIcon}
                              fontSize="small"
                            />
                          </ListItem>
                        </EditDrawer>
                      </List>
                    </Grid>
                  </Grid>
                </Portlet>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Portlet elevation={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={siteData?.active}
                          onChange={(_, active) => handleChangeActive(active)}
                          name="active"
                        />
                      }
                      label="Link ativo"
                      classes={{
                        label: customClasses.toggleTitle,
                      }}
                    />
                  </Grid>

                  {removeBrandEnabled && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={
                              !siteSettings?.brandSettings?.organizationBrand
                            }
                            onChange={(_, active) =>
                              handleChangeOrganizationBrand(!active)
                            }
                            name="active"
                          />
                        }
                        label={`Remover a marca do ${organization.data.name}`}
                        classes={{
                          label: customClasses.toggleTitle,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Portlet>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Portlet elevation={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="button">
                        INTEGRAÇÕES
                        <Tooltip
                          title="Adicione integrações com plataformas externas para entender e monitorar o tráfego do seu link."
                          enterTouchDelay={0}
                          arrow
                        >
                          <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <List disablePadding>
                        {integrationListItems.map((integration) => {
                          const id = siteIntegrations?.[integration?.type]?.id;
                          const integrationId =
                            siteIntegrations?.[integration?.type]
                              ?.integrationId;

                          return (
                            <EditDrawer
                              title={integration.name}
                              description={`Insira aqui o ID do ${integration.name}.`}
                              key={integration.type}
                              onSave={() =>
                                handleSaveIntegrationId(id, integration.name)
                              }
                              formComponent={
                                <Portlet elevation={1}>
                                  <InputLabel>
                                    {integration.inputLabel}
                                  </InputLabel>
                                  <TextField
                                    placeholder={integration.inputPlaceholder}
                                    variant="outlined"
                                    value={integration.inputMask(integrationId)}
                                    onChange={({ target }) =>
                                      handleChangeIntegrationId(
                                        id,
                                        integration.inputMask(target.value)
                                      )
                                    }
                                    helperText={integration.inputHelperText}
                                    fullWidth
                                  />
                                </Portlet>
                              }
                              pureChildren
                            >
                              <ListItem button disableGutters>
                                <ListItemText
                                  primary={integration.name}
                                  secondary={
                                    integrationId || "Insira seu ID aqui..."
                                  }
                                />

                                <ArrowRightIcon
                                  className={customClasses.listItemIcon}
                                  fontSize="small"
                                />
                              </ListItem>
                            </EditDrawer>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Portlet>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.deleteButton}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  PopConfirm.open({
                    description: "Deseja excluir este link?",
                    confirmButtonText: "Excluir",
                    onConfirm: async () => {
                      handleDeleteSite(siteId);
                    },
                  });
                }}
              >
                Excluir Link
              </Button>
            </Grid>
          </Grid>
        </Loading>
      }
      pureChildren
    >
      <ActionButton action="settings" />
    </EditDrawer>
  );
};

export default SiteSettingsEditDrawer;
