import { makeStyles } from "@material-ui/core";

import colors from "@/@fp/styles/colors";

export const HEADER_HEIGHT = 64;

const useStyles = makeStyles((theme) => ({
  logoImg: {
    height: "100%",
  },
  headerContainer: {
    height: `${HEADER_HEIGHT}px !important`,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1199,
  },
  headerContent: {
    height: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px !important`,
    width: "100%",
    backgroundColor: `${colors.greyScale[11]} !important`,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuButton: {
    padding: 0,
    "& svg": {
      height: theme.spacing(4),
      width: theme.spacing(4),
      color: "#212429",
    },
  },
  menuPaper: {
    marginTop: theme.spacing(5),
  },
  lowerCaseButton: {
    fontSize: "14px",
    textTransform: "none",
  },
  crownImage: {
    paddingRight: "5px",
  },
}));

export default useStyles;
