export const injectJS = (jsCode: string, selector: string) => {
	const elementId = selector;

	let script = document.getElementById(elementId);

	if (script) {
		script.remove();
	}

	script = document.createElement("script");
	script.id = elementId;

	const inlineScript = document.createTextNode(jsCode);

	script.appendChild(inlineScript);
	document.body.appendChild(script);
};

export const injectCSS = (cssCode: string, selector: string) => {
	const elementId = selector;

	let style = document.getElementById(elementId);

	if (!style) {
		style = document.createElement("style");
		style.id = elementId;

		const textNode = document.createTextNode(cssCode);
		style.appendChild(textNode);

		const livePreviewHead = document.getElementsByTagName("head")[0];

		livePreviewHead.appendChild(style);
	} else {
		style.innerHTML += cssCode;
	}
};

export const injectHTML = (html: string, selector: string) => {
	const elementId = selector;

	let element = document.getElementById(elementId);

	if (element) {
		element.remove();
	}

	element = document.createElement("div");
	element.id = elementId;
	element.innerHTML = html;

	document.body.appendChild(element);
};

export const injectScript = (src: string, selector: string) => {
	const elementId = selector;

	let script = document.getElementById(elementId) as HTMLScriptElement;

	if (script) {
		script.remove();
	}

	script = document.createElement("script");
	script.src = src;
	script.defer = true;
	script.id = elementId;

	document.body.appendChild(script);
};

export const globalVariableExists = (variableName: string): boolean => {
	// eslint-disable-next-line
	const windowObject = window as any

	const globalVariableExists = !!windowObject[variableName];

	return globalVariableExists;
};

// eslint-disable-next-line
export const getGlobalVariable = (variableName: string): any => {
	// eslint-disable-next-line
	const windowObject = window as any

	const globalVariableExists = windowObject[variableName];

	return globalVariableExists;
};

// eslint-disable-next-line
export const setGlobalVariable = (variableName: string, value: any): any => {
	// eslint-disable-next-line
	const windowObject = window as any

	const globalVariableExists = windowObject[variableName];

	if (globalVariableExists) {
		windowObject[variableName] = value;
	}
};

export const setFavicon = (url: string) => {
	let defaultFavicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

	if (!defaultFavicon) {
		defaultFavicon = document.createElement("link");

		document.head.appendChild(defaultFavicon);
	}

	defaultFavicon.type = "image/x-icon";
	defaultFavicon.rel = "shortcut icon";
	defaultFavicon.href = url;

	let iOSFavicon = document.querySelector("link[rel*='apple-touch-icon']") as HTMLLinkElement;

	if (!iOSFavicon) {
		iOSFavicon = document.createElement("link");

		document.head.appendChild(iOSFavicon);
	}

	iOSFavicon.type = "image/x-icon";
	iOSFavicon.rel = "shortcut icon";
	iOSFavicon.href = url;
};
